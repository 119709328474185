@import "../../../../sass/variables";

.move-money-popover-container {
  overflow: visible !important; }

.move-money-popover {
  background: white;
  border: 1px solid $primary;
  border-radius: 6px;
  padding: $half-gutter-width $full-gutter-width;

  .move-money-header {
    display: flex;
    align-items: center;
    padding-bottom: $half-gutter-width;

    .move-money-title {
      font-size: $standard-text;
      font-weight: bold;
      flex: 1; }

    .move-money-row-add-delete {
      margin-left: $full-gutter-width;

      .button-link {
        padding: 4px;
        margin-left: $quarter-gutter-width; } } }

  .move-money-category-row {
    display: flex;
    margin-bottom: $quarter-gutter-width;

    .editable-cell-input {
      width: 160px;
      margin-right: $half-gutter-width;
      border-radius: 4px;
      border: 1px solid #D2DCE5; }


    .editable-cell-input__value-container {
      position: relative; }

    .editable-cell-input__control--isFocused {
      border-color: #04B5C9; }

    .input-field {
      text-align: right;
      width: 50px;
      font-size: $standard-text; } }

  .budget-category-input-editable {
    border: 1px solid $border-color-light;
    border-radius: 4px;
    color: $text-dark;
    font-size: inherit;
    font-weight: 400;
    padding: 0 $quarter-gutter-width;
    // background-color: rgba(0,0,0,0)
    text-align: right;
    max-width: 75px;
    &:hover {
      transition: 250ms all;
      border: 1px solid $border-color-light; }
    &:focus {
      box-shadow: 0 0 0 1px $primary; } } }
