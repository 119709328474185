section.app-viewport {
  background-color: #F7F7F7;
  transition: margin-left 300ms;
  min-height: 100vh; }
  @media (min-width: 1024px) {
    section.app-viewport {
      margin-left: 275px; } }
  section.app-viewport.sidebar-disabled {
    margin-left: 0px; }

main.inner-wrapper {
  padding: 8px; }
  @media (min-width: 1024px) {
    main.inner-wrapper {
      padding: 16px; } }

[data-theme="dark"] section.app-viewport {
  background-color: #262D34;
  color: #C9CBCD; }
