.standard-editable-input {
  background: none;
  border-radius: 4px;
  height: min-content;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 2px 4px;
  font-size: inherit; }
  .standard-editable-input:hover {
    border-bottom: 1px dotted #949AA5; }
  .standard-editable-input:focus {
    background: #FFFFFF;
    border: 1px solid #04B5C9; }

[data-theme="dark"] .standard-editable-input {
  color: #C9CBCD; }

[data-theme="dark"] .standard-editable-input.is-editing {
  background-color: #343A42; }
