button.button-primary {
  background-color: #04B5C9;
  border: 1px solid #04B5C9;
  color: #FDFEFE; }
  button.button-primary:hover {
    background-color: #04acbf;
    cursor: pointer; }
  button.button-primary:active {
    background-color: #04a3b5; }

[data-theme="dark"] button.button-primary {
  background-color: #4D9EC7;
  border-color: #4D9EC7;
  color: #FDFEFE; }
  [data-theme="dark"] button.button-primary:hover {
    background-color: #3c93bf;
    border-color: #3c93bf;
    cursor: pointer; }
  [data-theme="dark"] button.button-primary:active {
    background-color: #3a8db7;
    border-color: #3a8db7; }
