button.button-secondary {
  background-color: #8ca0af;
  border: 1px solid #8ca0af;
  color: #FDFEFE; }
  button.button-secondary:hover {
    background-color: #869bab;
    cursor: pointer; }
  button.button-secondary:active {
    background-color: #8096a7; }

[data-theme="dark"] button.button-secondary {
  background-color: #627b8e;
  border-color: #627b8e;
  color: #FDFEFE; }
  [data-theme="dark"] button.button-secondary:hover {
    background-color: #586e7f;
    border-color: #586e7f;
    cursor: pointer; }
  [data-theme="dark"] button.button-secondary:active {
    background-color: #536979;
    border-color: #536979; }
