.account-search {
  max-width: 148px;
  margin-left: 16px; }

.account-header-wrapper {
  padding: 16px 12px; }
  .account-header-wrapper .input-field {
    width: 100%; }
  @media (min-width: 1024px) {
    .account-header-wrapper {
      padding: 16px 32px; } }
  .account-header-wrapper .account-header-controls-row {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 0; }
    @media (min-width: 768px) {
      .account-header-wrapper .account-header-controls-row {
        padding: 8px 0; } }
    .account-header-wrapper .account-header-controls-row .account-header-left-column, .account-header-wrapper .account-header-controls-row .account-header-right-column {
      display: flex;
      flex: 1; }
    .account-header-wrapper .account-header-controls-row .top-row {
      align-items: center;
      display: block; }
    .account-header-wrapper .account-header-controls-row .account-header-right-column {
      justify-content: flex-end;
      text-align: right; }
      .account-header-wrapper .account-header-controls-row .account-header-right-column button {
        margin-left: 8px; }
  .account-header-wrapper .upper-row .account-header-left-column {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 37%; }
    @media (min-width: 1024px) {
      .account-header-wrapper .upper-row .account-header-left-column {
        flex: 0 0 40%; } }
    @media (min-width: 1200px) {
      .account-header-wrapper .upper-row .account-header-left-column {
        flex: 1; } }
  .account-header-wrapper .upper-row .account-header-right-column {
    display: flex;
    flex: 0 0 63%; }
    @media (min-width: 1024px) {
      .account-header-wrapper .upper-row .account-header-right-column {
        flex: 0 0 60%; } }
    @media (min-width: 1200px) {
      .account-header-wrapper .upper-row .account-header-right-column {
        flex: 1; } }
  .account-header-wrapper .account-name {
    display: inline-flex;
    align-items: center;
    color: #52606D;
    font-weight: 700;
    font-size: 1.5rem;
    padding-left: 8px; }
  .account-header-wrapper .account-type {
    display: block;
    min-width: 100%;
    font-weight: 400;
    color: #626e7a;
    padding-left: 8px;
    font-style: italic;
    font-size: 0.9rem; }
  .account-header-wrapper .account-balance-block {
    margin-left: 32px; }
    .account-header-wrapper .account-balance-block .balance-amount {
      display: block;
      font-size: 1.5rem;
      font-weight: 600;
      color: #52606D; }
    .account-header-wrapper .account-balance-block .balance-description {
      font-weight: 400;
      color: #52606D; }
    .account-header-wrapper .account-balance-block .balance-total {
      color: #26a65b;
      font-weight: 700; }
  .account-header-wrapper .bottom-row {
    margin-top: 8px; }
    .account-header-wrapper .bottom-row .account-header-left-column {
      display: flex;
      align-items: center; }
  .account-header-wrapper .account-view-toggle {
    display: inline-block; }
    .account-header-wrapper .account-view-toggle .toggle-item {
      color: #52606D;
      font-size: 1rem;
      font-weight: 600;
      border-radius: 12px;
      padding: 4px 8px;
      margin-right: 4px; }
      .account-header-wrapper .account-view-toggle .toggle-item:hover {
        cursor: pointer; }
    .account-header-wrapper .account-view-toggle .is-toggled {
      background-color: #E4E7EB; }

.ReactVirtualized__Grid__innerScrollContainer {
  min-height: 100%; }

[data-theme="dark"] .account-header-wrapper .account-name {
  color: #E4E7EB; }

[data-theme="dark"] .account-header-wrapper .account-view-toggle .toggle-item {
  color: #949AA5; }

[data-theme="dark"] .account-header-wrapper .account-view-toggle .is-toggled {
  background-color: #343A42; }

[data-theme="dark"] .account-header-wrapper .balance-description {
  color: #C9CBCD; }

[data-theme="dark"] .account-header-wrapper .balance-amount {
  color: #B1B2B5; }

[data-theme="dark"] .account-header-wrapper .balance-total {
  color: #2dda75; }

[data-theme="dark"] .account-header-wrapper .input-field {
  background-color: #262D34;
  border-color: #949AA5;
  color: #C9CBCD; }
  [data-theme="dark"] .account-header-wrapper .input-field:focus {
    border-color: #4D9EC7; }
