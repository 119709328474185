.category-activity-table {
  width: 100%;
  color: #626e7a; }
  .category-activity-table table {
    min-width: 450px;
    width: 100%;
    border-collapse: collapse;
    max-height: 280px; }
  .category-activity-table thead {
    border-bottom: 1px solid #31ACDA; }
  .category-activity-table tbody {
    overflow: auto; }
    .category-activity-table tbody tr:nth-child(even) {
      background: #f7f7f7; }
      .category-activity-table tbody tr:nth-child(even) td {
        background: #f7f7f7; }
    .category-activity-table tbody .account-column {
      font-weight: 600;
      color: #52606D; }
  .category-activity-table .date-column, .category-activity-table .account-column, .category-activity-table .payee-column, .category-activity-table .notes-column {
    padding: 6px 8px;
    padding-right: 16px;
    text-align: left; }
  .category-activity-table .amount-column {
    padding: 6px 8px;
    padding-left: 32px;
    text-align: right;
    font-weight: 600;
    color: #52606D; }

[data-theme="dark"] .category-activity-table thead {
  color: #DBDCDD; }
  [data-theme="dark"] .category-activity-table thead tr {
    border-bottom: 1px solid #2FBFC4; }
    [data-theme="dark"] .category-activity-table thead tr td {
      padding-bottom: 8px; }
    [data-theme="dark"] .category-activity-table thead tr .amount-column {
      color: #DBDCDD; }

[data-theme="dark"] .category-activity-table tbody {
  color: #C9CBCD; }
  [data-theme="dark"] .category-activity-table tbody tr:nth-child(even) {
    background: #262D34; }
    [data-theme="dark"] .category-activity-table tbody tr:nth-child(even) td {
      background: #262D34; }
  [data-theme="dark"] .category-activity-table tbody .account-column {
    font-weight: 600;
    color: #31ACDA; }
  [data-theme="dark"] .category-activity-table tbody .amount-column {
    color: #DBDCDD; }
