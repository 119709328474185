.transaction-row-mobile {
  background-color: #ffffff;
  padding: 8px 30px;
  min-height: 37px;
  margin-bottom: 2px;
  border: 1px solid #ececec;
  border-radius: 2px; }
  .transaction-row-mobile .transaction-mobile-top-row, .transaction-row-mobile .transaction-mobile-bottom-row {
    display: flex;
    padding-right: 8px; }
    .transaction-row-mobile .transaction-mobile-top-row .mobile-label-payee, .transaction-row-mobile .transaction-mobile-top-row .mobile-label-category, .transaction-row-mobile .transaction-mobile-bottom-row .mobile-label-payee, .transaction-row-mobile .transaction-mobile-bottom-row .mobile-label-category {
      flex: 1; }
    .transaction-row-mobile .transaction-mobile-top-row .mobile-label-amount, .transaction-row-mobile .transaction-mobile-top-row .mobile-label-date, .transaction-row-mobile .transaction-mobile-bottom-row .mobile-label-amount, .transaction-row-mobile .transaction-mobile-bottom-row .mobile-label-date {
      text-align: right; }
    .transaction-row-mobile .transaction-mobile-top-row .mobile-label-payee, .transaction-row-mobile .transaction-mobile-bottom-row .mobile-label-payee {
      font-weight: 600;
      font-size: 1.2rem;
      color: #3E4C59; }
      .transaction-row-mobile .transaction-mobile-top-row .mobile-label-payee i, .transaction-row-mobile .transaction-mobile-bottom-row .mobile-label-payee i {
        margin-left: 8px;
        color: #C9C9C9; }
      .transaction-row-mobile .transaction-mobile-top-row .mobile-label-payee i.approved, .transaction-row-mobile .transaction-mobile-bottom-row .mobile-label-payee i.approved {
        color: #26a65b; }
    .transaction-row-mobile .transaction-mobile-top-row .mobile-label-amount, .transaction-row-mobile .transaction-mobile-bottom-row .mobile-label-amount {
      font-weight: 600;
      color: #3E4C59;
      font-size: 1.2rem; }
    .transaction-row-mobile .transaction-mobile-top-row .mobile-label-category, .transaction-row-mobile .transaction-mobile-top-row .mobile-label-date, .transaction-row-mobile .transaction-mobile-bottom-row .mobile-label-category, .transaction-row-mobile .transaction-mobile-bottom-row .mobile-label-date {
      font-size: 0.9rem;
      font-weight: 400;
      color: #52606D; }
  .transaction-row-mobile .mobile-transaction-details-icon {
    color: #888888;
    position: absolute;
    right: 10px;
    margin-top: -29px; }
  .transaction-row-mobile .inflow-transaction {
    color: #26a65b !important; }

.mobile-add-transaction-button {
  font-size: 1.2rem !important;
  position: fixed;
  z-index: 19;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 16px 0 !important;
  border-radius: 0 !important; }

[data-theme="dark"] .transaction-row-mobile {
  border-color: #585f6b;
  background-color: #343A42; }
  [data-theme="dark"] .transaction-row-mobile .mobile-label-payee, [data-theme="dark"] .transaction-row-mobile .mobile-label-amount {
    color: #DBDCDD; }
  [data-theme="dark"] .transaction-row-mobile .mobile-label-payee i.approved {
    color: #2dda75; }
  [data-theme="dark"] .transaction-row-mobile .mobile-label-category, [data-theme="dark"] .transaction-row-mobile .mobile-label-date {
    color: #B1B2B5; }
  [data-theme="dark"] .transaction-row-mobile .inflow-transaction {
    color: #2dda75 !important; }
