.empty-state-msg-budget {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #B1B2B5;
  font-weight: bold;
  text-align: center;
  margin-top: 50px;
  line-height: 1.3; }
  @media (min-width: 768px) {
    .empty-state-msg-budget {
      line-height: 1;
      margin-top: 80px;
      font-size: 3rem; } }

.underlined:hover {
  text-decoration: underline; }

.uncategorized-icon {
  color: #ff9f1a; }

.category-is-dragging {
  min-height: 22px;
  border: 1px dashed #31ACDA !important;
  border-radius: 2px;
  box-shadow: rgba(223, 225, 227, 0.7) 0 0 4px !important;
  margin: 4px; }

.category-group-is-dragging {
  border: 1px dashed #31ACDA !important;
  border-radius: 2px;
  box-shadow: rgba(223, 225, 227, 0.7) 0 0 4px !important;
  margin: 8px; }

.category-dropdown-toggle {
  position: absolute;
  width: 22px;
  text-align: center;
  right: 5px; }

.copy-last-month-budget-icon {
  color: #31ACDA;
  position: absolute !important;
  opacity: 0.6;
  transition: 0.25s ease-in-out; }
  @media (min-width: 768px) {
    .copy-last-month-budget-icon {
      display: none; }
      .copy-last-month-budget-icon:hover {
        cursor: pointer;
        transition: 0.25s ease-in-out;
        opacity: 1; } }

.budget-input-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center; }
  .budget-input-wrapper:hover .copy-last-month-budget-icon {
    display: initial; }

.budget-table-wrapper .budget-table-row .budget-column-name input {
  width: 69%; }
  @media (min-width: 375px) {
    .budget-table-wrapper .budget-table-row .budget-column-name input {
      width: 75%; } }
  @media (min-width: 414px) {
    .budget-table-wrapper .budget-table-row .budget-column-name input {
      width: inherit; } }

@media (max-width: 640px) {
  .budget-table-wrapper .budget-table-row .budget-column-name .add-notes-button {
    margin-left: 0; } }

.category-dropdown-menu {
  display: none;
  padding: 4px 0;
  margin: 5px 0 0;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  z-index: 100;
  position: absolute;
  list-style: none;
  background-color: #fff;
  text-align: left;
  list-style: none;
  float: left;
  left: auto;
  right: -8px;
  top: 25px; }
  .category-dropdown-menu:before {
    position: absolute;
    top: -11px;
    right: 8px;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 11px;
    border-color: transparent transparent #d8d8d8 transparent; }
  .category-dropdown-menu:after {
    position: absolute;
    top: -9px;
    right: 9px;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 10px;
    border-color: transparent transparent #FFFFFF transparent; }

.category-dropdown-size {
  min-width: 150px; }

.category-group-dropdown-size {
  min-width: 185px; }

.category-dropdown-open {
  display: block; }
  .category-dropdown-open li {
    padding: 6px 8px;
    font-weight: 600;
    color: #626e7a; }
    .category-dropdown-open li i {
      margin-right: 8px; }
    .category-dropdown-open li .is-warning {
      color: #DA7A7A; }
    .category-dropdown-open li:hover {
      background-color: rgba(8, 180, 201, 0.1); }

.dropdown-backdrop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0; }
  .dropdown-backdrop:hover {
    cursor: initial; }

.category-dropdown-toggle .dropdown-backdrop {
  z-index: 90; }

.budget-table-wrapper {
  margin: 32px 0;
  padding: 0;
  height: 70vh; }
  @media (min-width: 375px) {
    .budget-table-wrapper {
      height: 74vh; } }
  @media (min-width: 414px) {
    .budget-table-wrapper {
      height: 76vh; } }
  @media (min-width: 768px) {
    .budget-table-wrapper {
      height: 81vh; } }
  @media (max-width: 1023px) {
    .budget-table-wrapper {
      overflow: hidden; } }
  @media (min-width: 1024px) {
    .budget-table-wrapper {
      padding: 0;
      display: flex;
      flex-flow: column;
      height: 87vh; } }
  @media (min-width: 1440px) {
    .budget-table-wrapper {
      max-width: 75%; } }
  .budget-table-wrapper .budget-table-row {
    display: flex;
    flex-direction: row;
    position: relative;
    border-bottom: 1px solid #DFE1E3;
    padding: 7px 12px; }
    @media (min-width: 1024px) {
      .budget-table-wrapper .budget-table-row {
        padding: 4px 40px 4px 8px; } }
    @media (min-width: 1440px) {
      .budget-table-wrapper .budget-table-row {
        padding: 7px 40px 7px 20px; } }
    .budget-table-wrapper .budget-table-row .editing {
      opacity: 0.75;
      padding: 0 4px;
      border-bottom: 1px dashed #04cce2;
      margin-left: -4px;
      margin-right: -4px; }
    .budget-table-wrapper .budget-table-row .budget-column-name {
      flex: 1; }
      .budget-table-wrapper .budget-table-row .budget-column-name i {
        margin-left: 8px;
        color: #04B5C9; }
        .budget-table-wrapper .budget-table-row .budget-column-name i:hover {
          color: #049eb0; }
    .budget-table-wrapper .budget-table-row .budget-column-date, .budget-table-wrapper .budget-table-row .budget-column-budgeted, .budget-table-wrapper .budget-table-row .budget-column-activity, .budget-table-wrapper .budget-table-row .budget-column-remaining {
      flex: 0 0 27%; }
      @media (min-width: 1024px) {
        .budget-table-wrapper .budget-table-row .budget-column-date, .budget-table-wrapper .budget-table-row .budget-column-budgeted, .budget-table-wrapper .budget-table-row .budget-column-activity, .budget-table-wrapper .budget-table-row .budget-column-remaining {
          flex: 0 0 16.5%; } }
    .budget-table-wrapper .budget-table-row .budget-column-date {
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex; }
    .budget-table-wrapper .budget-table-row .budget-column-activity, .budget-table-wrapper .budget-table-row .budget-column-remaining, .budget-table-wrapper .budget-table-row .budget-column-budgeted {
      text-align: right; }
    .budget-table-wrapper .budget-table-row .due-date__value-container {
      justify-content: center;
      padding: 0; }
      .budget-table-wrapper .budget-table-row .due-date__value-container div {
        padding: 0;
        margin-left: -1px; }
    .budget-table-wrapper .budget-table-row .due-date__control {
      min-height: min-content;
      border: none;
      background-color: #FFFFFF;
      transition: none; }
    .budget-table-wrapper .budget-table-row .due-date__control--isFocused {
      transition: 250ms all;
      box-shadow: 0 0 0 1px #04B5C9; }
  .budget-table-wrapper .header-row {
    color: #626e7a;
    font-weight: 600;
    font-size: 0.9rem; }
    @media (min-width: 1024px) {
      .budget-table-wrapper .header-row {
        margin: 0 20px 0 10px; } }
    @media (min-width: 1920) {
      .budget-table-wrapper .header-row {
        font-size: 1rem; } }
    @media (max-width: 767px) {
      .budget-table-wrapper .header-row .budget-column-remaining {
        padding-right: 6px; } }
    .budget-table-wrapper .header-row .header-amount {
      color: #3E4C59;
      font-weight: 700; }
    @media (min-width: 768px) {
      .budget-table-wrapper .header-row .header-budgeted-cell {
        width: 99px;
        display: flex;
        justify-items: flex-end;
        margin-left: auto; } }
    .budget-table-wrapper .header-row .has-uncategorized {
      border-radius: 4px;
      padding: 1px 3px 1px 6px;
      background-color: #ff9f1a !important; }

.wrapper-multi-month {
  max-width: 100%;
  overflow: auto; }
  @media (min-width: 1024px) {
    .wrapper-multi-month .budget-table-row .budget-column-date, .wrapper-multi-month .budget-table-row .budget-column-budgeted, .wrapper-multi-month .budget-table-row .budget-column-activity, .wrapper-multi-month .budget-table-row .budget-column-remaining {
      flex: 0 0 8.5%; } }

.table-body-wrapper-multi-month {
  max-width: 100%;
  overflow: auto; }

.ltb-row {
  display: flex; }

.ltb-spacer-1 {
  flex: 1 1; }

.ltb-spacer-2 {
  flex: 0 0 8.5%; }

.ltb-multi-month-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 8px;
  flex: 0 0 24.8%; }
  @media (min-width: 1540px) {
    .ltb-multi-month-wrapper {
      flex: 0 0 25.5%; } }
  .ltb-multi-month-wrapper:first-child {
    padding-left: 20px; }
  .ltb-multi-month-wrapper:last-child {
    margin-right: 40px;
    padding-right: 20px; }

.ltb-multi-month-inner {
  border: 1px solid #D2DCE5;
  border-radius: 4px;
  background-color: white;
  margin-left: 20px;
  width: 247px;
  padding-bottom: 14px;
  box-shadow: rgba(223, 225, 227, 0.7) 0 0 4px; }
  .ltb-multi-month-inner .multi-month-date {
    font-size: 1.3rem;
    color: #626e7a;
    font-weight: 700;
    margin-top: 8px;
    margin-bottom: 8px;
    display: block;
    text-align: center; }

.multi-month-line-item {
  padding: 0 16px;
  display: flex;
  flex: 1; }
  .multi-month-line-item .multi-month-left-item {
    flex: 1;
    color: #616E7C; }
  .multi-month-line-item .multi-month-right-item {
    text-align: right;
    color: #626e7a;
    font-weight: 700; }

.ltb-line-item {
  margin-top: 16px; }
  .ltb-line-item .multi-month-left-item {
    display: flex;
    align-items: center; }
  .ltb-line-item .ltb-amount {
    font-size: 1.3rem; }

.budget-table-body-wrapper {
  width: 100%;
  overflow: auto; }

.budget-category-group {
  margin: 0 4px 8px 8px; }
  @media (min-width: 1024px) {
    .budget-category-group {
      margin: 0 20px 8px 10px; } }

.budget-table-body-wrapper {
  margin: 16px 0;
  overflow: scroll;
  height: 56vh; }
  @media (min-width: 375px) {
    .budget-table-body-wrapper {
      height: 64vh; } }
  @media (min-width: 414px) {
    .budget-table-body-wrapper {
      height: 66vh; } }
  @media (min-width: 768px) {
    .budget-table-body-wrapper {
      height: 66vh; } }
  @media (min-width: 1024px) {
    .budget-table-body-wrapper {
      overflow: overlay;
      flex-grow: 1;
      padding-bottom: 120px; } }
  .budget-table-body-wrapper .category-group-row {
    color: #52606D;
    font-weight: 700;
    font-size: 0.9rem;
    border-bottom: 2px solid #04B5C9; }
    .budget-table-body-wrapper .category-group-row:hover {
      background-color: #eaeaea; }
    @media (min-width: 1920px) {
      .budget-table-body-wrapper .category-group-row {
        font-size: 1rem; } }
    .budget-table-body-wrapper .category-group-row .standard-editable-input {
      color: #52606D;
      font-weight: 700; }
    .budget-table-body-wrapper .category-group-row .button-icon {
      padding: 0 1px; }
      .budget-table-body-wrapper .category-group-row .button-icon:hover {
        background-color: #d1d1d1;
        border-radius: 8px; }
    .budget-table-body-wrapper .category-group-row .collapse-icon {
      position: absolute;
      left: 2px;
      top: 25%; }
      @media (max-width: 767px) {
        .budget-table-body-wrapper .category-group-row .collapse-icon {
          left: -4px; } }
    @media (max-width: 767px) {
      .budget-table-body-wrapper .category-group-row .budget-column-budgeted, .budget-table-body-wrapper .category-group-row .budget-column-remaining {
        padding-right: 3px; } }
  .budget-table-body-wrapper .category-group-selected {
    background-color: #08b4c9;
    color: #FDFEFE; }
    .budget-table-body-wrapper .category-group-selected:hover {
      background-color: #049eb0;
      border-color: #049eb0; }
  .budget-table-body-wrapper .category-row-selected {
    background-color: rgba(8, 180, 201, 0.2); }
    .budget-table-body-wrapper .category-row-selected:hover {
      background-color: rgba(8, 180, 201, 0.3) !important; }
  .budget-table-body-wrapper .budget-table-category-body {
    background-color: #FFFFFF;
    border-left: 1px solid #DFE1E3;
    border-right: 1px solid #DFE1E3;
    border-bottom: 1px solid #DFE1E3;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: rgba(223, 225, 227, 0.7) 0 0 4px; }
  .budget-table-body-wrapper .category-row:first-child {
    border-top: none; }
  .budget-table-body-wrapper .category-row {
    color: #626e7a;
    font-weight: 400;
    font-size: 0.9rem;
    border: none;
    padding-top: 0px;
    padding-bottom: 0px;
    border-top: 1px solid #E8EAEE;
    align-items: center; }
    .budget-table-body-wrapper .category-row:hover {
      background-color: #f2f2f2; }
      .budget-table-body-wrapper .category-row:hover .budget-column-activity span.has-activity:hover {
        cursor: pointer;
        text-decoration: underline; }
    @media (min-width: 1920px) {
      .budget-table-body-wrapper .category-row {
        font-size: 1rem; } }
    .budget-table-body-wrapper .category-row .budget-column-name, .budget-table-body-wrapper .category-row .budget-column-date, .budget-table-body-wrapper .category-row .budget-column-budgeted, .budget-table-body-wrapper .category-row .budget-column-activity, .budget-table-body-wrapper .category-row .budget-column-remaining {
      padding-top: 2px;
      padding-bottom: 2px; }
  .budget-table-body-wrapper .category-row .add-notes-button {
    opacity: 1;
    visibility: visible;
    margin-left: 4px; }
    @media (min-width: 1024px) {
      .budget-table-body-wrapper .category-row .add-notes-button {
        opacity: 0;
        visibility: hidden; } }
  .budget-table-body-wrapper .category-row:hover .add-notes-button {
    opacity: 1;
    visibility: visible; }
    .budget-table-body-wrapper .category-row:hover .add-notes-button:hover {
      cursor: pointer; }
  .budget-table-body-wrapper .due-date__single-value {
    color: #626e7a;
    font-weight: 400;
    align-items: center; }
  .budget-table-body-wrapper .budget-category-input-editable {
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 4px;
    color: #626e7a;
    font-size: inherit;
    font-weight: 400;
    text-align: right;
    max-width: 75px; }
    .budget-table-body-wrapper .budget-category-input-editable:hover {
      transition: 250ms all;
      border: 1px solid #E8EAEE; }
    .budget-table-body-wrapper .budget-category-input-editable:focus {
      box-shadow: 0 0 0 1px #04B5C9; }
    @media (min-width: 1900px) {
      .budget-table-body-wrapper .budget-category-input-editable {
        max-width: 95px; } }

.budget-table-wrapper .month-group {
  flex: 0 0 50%;
  display: flex; }
  .budget-table-wrapper .month-group .budget-column-budgeted, .budget-table-wrapper .month-group .budget-column-activity, .budget-table-wrapper .month-group .budget-column-remaining {
    flex: 1; }
    @media (min-width: 1024px) {
      .budget-table-wrapper .month-group .budget-column-budgeted, .budget-table-wrapper .month-group .budget-column-activity, .budget-table-wrapper .month-group .budget-column-remaining {
        flex: 0 0 33.3%; } }

.budget-table-wrapper.wrapper-multi-month .category-group-row {
  padding-top: 0;
  padding-bottom: 0; }

.budget-table-wrapper.wrapper-multi-month .budget-column-name, .budget-table-wrapper.wrapper-multi-month .budget-column-date, .budget-table-wrapper.wrapper-multi-month .budget-column-budgeted, .budget-table-wrapper.wrapper-multi-month .budget-column-activity, .budget-table-wrapper.wrapper-multi-month .budget-column-remaining {
  padding-top: 4px;
  padding-bottom: 4px; }

.budget-table-wrapper.wrapper-multi-month .budget-column-name {
  padding-left: 4px; }

.budget-table-wrapper.wrapper-multi-month .budget-category-group {
  margin-bottom: 0; }

.budget-table-wrapper.wrapper-multi-month .month-group {
  flex: 0 0 25.5%;
  display: flex; }
  .budget-table-wrapper.wrapper-multi-month .month-group .budget-column-budgeted, .budget-table-wrapper.wrapper-multi-month .month-group .budget-column-activity, .budget-table-wrapper.wrapper-multi-month .month-group .budget-column-remaining {
    flex: 0 0 33%; }

.budget-table-wrapper.wrapper-multi-month .budget-column-remaining {
  padding-right: 8px; }

.budget-table-wrapper.wrapper-multi-month .is-middle-column {
  padding: 0 16px 0 0;
  margin-left: 16px; }

.table-body-wrapper-multi-month {
  margin-top: 0; }
  .table-body-wrapper-multi-month .is-middle-column {
    background-color: #f4f4f4; }
    .table-body-wrapper-multi-month .is-middle-column .budget-category-input-editable {
      background-color: #f4f4f4; }
  .table-body-wrapper-multi-month .category-row:hover input.budget-category-input-editable {
    background: #FFFFFF; }
  .table-body-wrapper-multi-month .category-row .budget-column-name, .table-body-wrapper-multi-month .category-group-row .budget-column-name {
    display: flex;
    align-items: center; }
    .table-body-wrapper-multi-month .category-row .budget-column-name input, .table-body-wrapper-multi-month .category-group-row .budget-column-name input {
      white-space: nowrap;
      max-width: 91px;
      text-overflow: ellipsis;
      overflow: hidden; }
      @media (min-width: 1600px) {
        .table-body-wrapper-multi-month .category-row .budget-column-name input, .table-body-wrapper-multi-month .category-group-row .budget-column-name input {
          max-width: 115px; } }
      @media (min-width: 1920px) {
        .table-body-wrapper-multi-month .category-row .budget-column-name input, .table-body-wrapper-multi-month .category-group-row .budget-column-name input {
          max-width: 162px; } }

.credit-card-row-header-label {
  font-size: 0.9rem;
  color: #626e7a;
  font-weight: 600; }

.credit-card-row .budget-column-name {
  padding-left: 3px; }

[data-theme="dark"] .budget-table-wrapper .budget-table-row {
  border-color: #3C4149; }

[data-theme="dark"] .budget-table-wrapper .header-row {
  color: #B1B2B5; }
  [data-theme="dark"] .budget-table-wrapper .header-row .header-amount {
    color: #DBDCDD; }
  [data-theme="dark"] .budget-table-wrapper .header-row .has-uncategorized {
    color: #232323 !important; }

[data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .category-group-row {
  color: #2FBFC4;
  border-color: #2FBFC4; }
  [data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .category-group-row:hover {
    background-color: #3c4751; }
  [data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .category-group-row .standard-editable-input {
    color: #2FBFC4;
    font-weight: 700; }

[data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .category-row {
  color: #C9CBCD;
  border-color: #3C4149; }
  [data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .category-row:hover {
    background-color: #3f4750; }

[data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .due-date__single-value {
  color: #C9CBCD; }

[data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .budget-table-category-body {
  background-color: #343A42;
  border-color: #2E383F;
  box-shadow: #1e1e1e 0 0 4px; }

[data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .due-date__control {
  background-color: #343A42; }

[data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .due-date__control--isFocused {
  box-shadow: 0 0 0 1px #31ACDA; }

[data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .due-date__value-container * {
  color: #C9CBCD !important; }

[data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .due-date__menu {
  background-color: #343A42; }

[data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .due-date__option {
  color: #C9CBCD; }

[data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .due-date__option--isFocused {
  background-color: rgba(4, 181, 201, 0.2);
  color: #E4E7EB; }

[data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .budget-category-input-editable {
  background-color: #343A42;
  color: #C9CBCD; }
  [data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .budget-category-input-editable:hover {
    border: 1px solid #3C4149; }
  [data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .budget-category-input-editable:focus {
    box-shadow: 0 0 0 1px #31ACDA; }

[data-theme="dark"] .budget-table-wrapper .ltb-multi-month-inner {
  background-color: #343A42;
  border-color: #2E383F;
  box-shadow: #1e1e1e 0 0 4px; }
  [data-theme="dark"] .budget-table-wrapper .ltb-multi-month-inner .multi-month-date {
    color: #C9CBCD; }
  [data-theme="dark"] .budget-table-wrapper .ltb-multi-month-inner .multi-month-left-item {
    color: #949AA5; }
  [data-theme="dark"] .budget-table-wrapper .ltb-multi-month-inner .multi-month-right-item {
    color: #C9CBCD; }

[data-theme="dark"] .budget-table-wrapper .table-body-wrapper-multi-month .is-middle-column {
  background-color: #22272A; }
  [data-theme="dark"] .budget-table-wrapper .table-body-wrapper-multi-month .is-middle-column .budget-category-input-editable {
    background-color: #22272A; }

[data-theme="dark"] .budget-table-wrapper .table-body-wrapper-multi-month .category-row:hover input.budget-category-input-editable {
  background: #343A42; }
  [data-theme="dark"] .budget-table-wrapper .table-body-wrapper-multi-month .category-row:hover input.budget-category-input-editable:active, [data-theme="dark"] .budget-table-wrapper .table-body-wrapper-multi-month .category-row:hover input.budget-category-input-editable:focus {
    background: #343A42; }

[data-theme="dark"] .budget-table-wrapper .table-body-wrapper-multi-month .category-row input.budget-category-input-editable:active, [data-theme="dark"] .budget-table-wrapper .table-body-wrapper-multi-month .category-row input.budget-category-input-editable:focus {
  background: #343A42; }

[data-theme="dark"] .budget-table-wrapper .credit-card-row-header-label {
  color: #B1B2B5; }

[data-theme="dark"] .budget-table-wrapper .credit-card-row-header-amount {
  color: #DBDCDD; }
