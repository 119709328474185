.manage-payees {
  width: 100%;
  max-height: 400px; }
  .manage-payees .payee-list {
    display: flex;
    height: 300px;
    min-width: 100%;
    margin-bottom: 30px; }
  .manage-payees .form-controls-group {
    width: 100%;
    display: block;
    text-align: right; }
    .manage-payees .form-controls-group button {
      margin-left: 8px; }

[data-theme="dark"] .manage-payees label {
  color: #DBDCDD; }

[data-theme="dark"] .manage-payees input {
  background-color: #262D34;
  border-color: #3C4149;
  color: #C9CBCD; }
  [data-theme="dark"] .manage-payees input:focus {
    border-color: #31ACDA; }
