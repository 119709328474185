button.button-link {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  box-shadow: none;
  color: #626e7a; }
  button.button-link:hover {
    background-color: #eaeaea;
    cursor: pointer; }
  button.button-link:active {
    background-color: #e5e5e5; }

[data-theme="dark"] button.button-link {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  box-shadow: none; }
  [data-theme="dark"] button.button-link:hover {
    background-color: #20252b;
    border-color: #20252b;
    cursor: pointer; }
  [data-theme="dark"] button.button-link:active {
    background-color: #1b2025;
    border-color: #1b2025; }
