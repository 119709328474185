button.button-warning {
  background-color: #DA7A7A;
  border: 1px solid #DA7A7A;
  color: #FDFEFE; }
  button.button-warning:hover {
    background-color: #d87272;
    cursor: pointer; }
  button.button-warning:active {
    background-color: #d66a6a; }

[data-theme="dark"] button.button-warning {
  background-color: #DA7A7A;
  border-color: #DA7A7A;
  color: #FDFEFE; }
  [data-theme="dark"] button.button-warning:hover {
    background-color: #d46666;
    border-color: #d46666;
    cursor: pointer; }
  [data-theme="dark"] button.button-warning:active {
    background-color: #d25e5e;
    border-color: #d25e5e; }
