.react-confirm-alert-overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.3) !important;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.01s 0.01s forwards !important;
  -moz-animation: react-confirm-alert-fadeIn 0.01s 0.01s forwards !important;
  -o-animation: react-confirm-alert-fadeIn 0.01s 0.01s forwards !important;
  animation: react-confirm-alert-fadeIn 0.01s 0.01s forwards !important; }

.react-confirm-alert-blur {
  transition: 100ms all;
  filter: none !important;
  -webkit-filter: none !important; }

.modal-base {
  min-width: 305px;
  background-color: #FFFFFF;
  box-shadow: rgba(223, 225, 227, 0.7) 0 0 4px;
  border-radius: 8px;
  padding-bottom: 16px; }
  @media (max-width: 640px) {
    .modal-base {
      height: 100vh;
      width: 100vw;
      position: fixed;
      left: 0;
      top: 0; } }
  @media (min-width: 375px) {
    .modal-base {
      min-width: 345px; } }
  @media (min-width: 1024px) {
    .modal-base {
      min-width: 310px; } }
  @media (min-width: 1440px) {
    .modal-base {
      margin-right: -210px; } }
  @media (min-width: 1600px) {
    .modal-base {
      margin-right: -180px; } }
  .modal-base .modal-header {
    padding: 12px 16px;
    display: flex;
    border-bottom: 1px solid #E8EAEE;
    align-items: center; }
    .modal-base .modal-header .modal-title {
      display: inline-block;
      flex: 1;
      font-size: 1.3rem;
      color: #626e7a;
      margin-right: 32px;
      opacity: 0.7; }
      .modal-base .modal-header .modal-title:hover {
        transition: 150ms all;
        opacity: 1;
        cursor: pointer; }
  .modal-base .modal-body {
    height: 80vh;
    display: flex;
    padding: 16px;
    overflow-y: auto;
    overflow-x: hidden; }
    @media (min-width: 1024px) {
      .modal-base .modal-body {
        height: 100%;
        overflow: initial;
        max-height: none; } }
  .modal-base .input-field {
    width: 100%; }

.new-transaction-modal .form-group {
  justify-content: space-between;
  flex-flow: row !important; }

@media (min-width: 1024px) {
  .new-transaction-modal input {
    width: 138px; } }

.new-transaction-modal .input-label {
  align-items: center;
  flex: 0 0 50%; }

.new-transaction-modal .editable-cell-input {
  border: 1px solid #D2DCE5;
  border-radius: 4px;
  padding: 4px 8px; }
  .new-transaction-modal .editable-cell-input .editable-cell-input__control {
    padding-left: 0; }

.new-transaction-modal .editable-cell-input__value-container {
  padding-left: 0; }

.new-transfer-modal .DayPickerInput {
  display: flex;
  width: 100%; }

.new-transfer-modal .form-group {
  justify-content: space-between;
  flex-flow: row !important; }

.new-transfer-modal .input-label {
  align-items: center;
  flex: 0 0 50%; }

.new-transfer-modal .editable-cell-input {
  border: 1px solid #D2DCE5;
  border-radius: 4px;
  padding: 4px 8px; }
  .new-transfer-modal .editable-cell-input .editable-cell-input__control {
    padding-left: 0; }

.new-transfer-modal .editable-cell-input__value-container {
  padding-left: 0; }

.expense-income-switch {
  display: flex;
  justify-content: center;
  margin-bottom: 16px; }
  .expense-income-switch .selectable-pill {
    width: 45%;
    text-align: center;
    display: inline-block;
    -ms-flex: 0 1;
    flex: 0 1;
    padding: 8px 35px;
    font-weight: 500;
    border: 2px solid transparent;
    margin: 0 8px; }
  .expense-income-switch .outflow-selected {
    border: 2px solid #de7f40;
    border-radius: 20px;
    background-color: #de7f40;
    color: #fdfefe;
    font-weight: 600; }
  .expense-income-switch .inflow-selected {
    border: 2px solid #26a65b;
    border-radius: 20px;
    background-color: #26a65b;
    color: #fdfefe;
    font-weight: 600; }

.recurring-cancel {
  max-width: 450px; }
  .recurring-cancel .alert-icon {
    margin-right: 6px; }
  .recurring-cancel .alert-title {
    display: block;
    font-size: 1.1rem;
    color: #3E4C59;
    font-weight: 600;
    margin-bottom: 8px; }
  .recurring-cancel .alert-text {
    display: block;
    margin-bottom: 16px;
    color: #52606D; }
  .recurring-cancel .alert-button-container {
    text-align: right; }
    .recurring-cancel .alert-button-container button {
      margin-left: 16px; }

[data-theme="dark"] .modal-base {
  background-color: #343A42;
  box-shadow: #1e1e1e 0 0 4px; }
  [data-theme="dark"] .modal-base .alert-title, [data-theme="dark"] .modal-base .alert-text {
    color: #C9CBCD; }
  [data-theme="dark"] .modal-base .modal-header {
    border-bottom: 1px solid #262D34; }
    [data-theme="dark"] .modal-base .modal-header .modal-title, [data-theme="dark"] .modal-base .modal-header i {
      color: #C9CBCD; }
  [data-theme="dark"] .modal-base .modal-body {
    background-color: #2E383F; }
    [data-theme="dark"] .modal-base .modal-body .expense-income-switch .selectable-pill {
      color: #DBDCDD; }
    [data-theme="dark"] .modal-base .modal-body .expense-income-switch .inflow-selected, [data-theme="dark"] .modal-base .modal-body .expense-income-switch .outflow-selected {
      color: #FDFEFE; }
  [data-theme="dark"] .modal-base .editable-cell-input {
    border-color: #3C4149; }
  [data-theme="dark"] .modal-base .editable-cell-input-standalone, [data-theme="dark"] .modal-base .editable-cell-input, [data-theme="dark"] .modal-base .DayPickerInput input, [data-theme="dark"] .modal-base .editable-cell-input__placeholder, [data-theme="dark"] .modal-base .editable-cell-input__single-value {
    background: #262D34;
    color: #DBDCDD; }
  [data-theme="dark"] .modal-base .editable-cell-input-standalone, [data-theme="dark"] .modal-base .editable-cell-input, [data-theme="dark"] .modal-base .DayPickerInput input, [data-theme="dark"] .modal-base .editable-cell-input__placeholder, [data-theme="dark"] .modal-base .editable-cell-input__single-value {
    background: #262D34;
    color: #DBDCDD; }
  [data-theme="dark"] .modal-base .editable-cell-input__value-container--hasValue * {
    color: #DBDCDD !important; }
