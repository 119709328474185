.registration-logo {
  position: absolute; }
  .registration-logo img {
    padding: 20px;
    width: 220px;
    height: auto; }

.registration-wrapper {
  background-color: #262D34;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw; }
  .registration-wrapper .card-column {
    width: 290px; }
    .registration-wrapper .card-column .registration-title {
      margin-bottom: 16px;
      text-align: center;
      font-size: 2rem;
      color: #FDFEFE; }
    .registration-wrapper .card-column .registration-form {
      box-shadow: #1e1e1e 0 0 4px;
      background-color: #f7f7f7;
      padding: 15px 25px;
      min-height: 260px;
      margin-bottom: 20px;
      border-radius: 4px; }

.registration-form-inner {
  margin-top: 16px; }
  .registration-form-inner form div {
    display: flex;
    flex-flow: column; }
  .registration-form-inner .input-label {
    font-weight: 600;
    font-size: 1rem;
    color: #52606D;
    flex: 1;
    display: flex;
    margin-bottom: 4px; }
    .registration-form-inner .input-label i {
      margin-left: 2px; }
  .registration-form-inner .registration-form-button {
    margin-top: 8px;
    padding: 6px 16px;
    width: 100%; }
  .registration-form-inner .forgot-password-link {
    display: block;
    width: 100%;
    margin-top: 8px; }
    .registration-form-inner .forgot-password-link button {
      width: 100%;
      padding: 6px 16px; }
  .registration-form-inner .form-input {
    -webkit-appearance: none;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 1rem;
    height: 2.25em;
    -ms-flex-pack: start;
    justify-content: flex-start;
    line-height: 1.5;
    padding: 4px 8px;
    position: relative;
    margin-bottom: 4px;
    vertical-align: top;
    background-color: #fff;
    border-color: #dbdbdb;
    color: #363636;
    -webkit-box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
    max-width: 100%; }
    .registration-form-inner .form-input:focus {
      border-color: #31ACDA; }

.no-account {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-flow: column; }
  .no-account .no-account-text {
    width: 100%;
    color: #C9CBCD; }
  .no-account a {
    margin: 0 auto;
    width: 110px; }
  .no-account button {
    font-weight: 700;
    color: #04B5C9; }

.demo-text {
  line-height: 1.5em;
  text-align: center;
  margin-top: 20px;
  display: block;
  font-size: 0.85rem;
  color: #626e7a; }
