.ltb-tablet-month {
  display: flex;
  flex: 1;
  color: #616E7C;
  margin: 0 16px 32px; }
  .ltb-tablet-month .ltb-wrapper {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-content: space-between; }
  .ltb-tablet-month .ltb-loading {
    color: #04B5C9;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    width: 100%;
    font-size: 3rem;
    vertical-align: middle;
    transition: 300ms all; }
  .ltb-tablet-month .ltb-section {
    border-radius: 4px;
    padding: 8px 16px;
    min-width: 190px;
    background: #FFFFFF; }
    .ltb-tablet-month .ltb-section p.ltb-line-item {
      display: flex;
      margin-block-start: 0;
      margin-block-end: 0; }
    .ltb-tablet-month .ltb-section .ltb-amount {
      flex: 1;
      font-weight: 700;
      display: flex;
      justify-content: flex-end;
      color: #626e7a; }
  .ltb-tablet-month .left-to-budget-section {
    min-width: initial; }
    .ltb-tablet-month .left-to-budget-section p.left-to-budget-line-item {
      display: block; }
      .ltb-tablet-month .left-to-budget-section p.left-to-budget-line-item span {
        display: block;
        text-align: right; }
      .ltb-tablet-month .left-to-budget-section p.left-to-budget-line-item .ltb-amount {
        display: block;
        font-size: 1.3rem;
        color: #52606D; }

[data-theme="dark"] .ltb-tablet-month {
  color: #949AA5; }
  [data-theme="dark"] .ltb-tablet-month .ltb-section {
    background-color: #343A42; }
    [data-theme="dark"] .ltb-tablet-month .ltb-section .ltb-amount {
      color: #C9CBCD; }
