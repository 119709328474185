header.header {
  background-color: #FFFFFF;
  border-bottom: 1px solid #D2DCE5;
  display: flex;
  height: 44px;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px; }
  @media (min-width: 1024px) {
    header.header {
      height: 35px; } }
  header.header .sidebar-collapse-icon:hover {
    cursor: pointer; }
  header.header .header-right-side {
    align-items: stretch;
    display: flex;
    justify-content: flex-end;
    margin-left: auto; }
    header.header .header-right-side ul.header-nav {
      list-style-type: none;
      padding-inline-start: 0;
      margin-block-start: 0;
      margin-block-end: 0; }
      header.header .header-right-side ul.header-nav li.header-nav-item {
        display: inline-block;
        padding: 8px 8px;
        margin-left: 16px; }
        header.header .header-right-side ul.header-nav li.header-nav-item:hover {
          border-radius: 4px;
          cursor: pointer;
          background-color: #F1f2f2; }
        header.header .header-right-side ul.header-nav li.header-nav-item span {
          color: #52606D;
          text-align: center;
          font-weight: 600; }

[data-theme="dark"] header.header {
  background-color: #343A42;
  border-bottom: 1px solid #343A42; }
  [data-theme="dark"] header.header .sidebar-collapse-icon {
    color: #C9CBCD; }
