.transfer-type-icon {
  margin-right: 4px; }

.inner-wrapper.account-wrapper {
  height: calc(100vh - 220px); }
  @media (min-width: 1024px) {
    .inner-wrapper.account-wrapper {
      height: calc(100vh - 221px); } }

.transaction-row {
  background-color: #FFFFFF;
  display: block;
  border: 1px solid #ececec;
  padding: 0 16px;
  height: 31px;
  margin: 2px 32px;
  align-items: center;
  border-radius: 2px; }
  .transaction-row:hover {
    transition: all 0.13s;
    border: 1px solid #C9C9C9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.09); }
  .transaction-row .transaction-column {
    display: flex;
    color: #3E4C59;
    margin: 0 2px; }
  .transaction-row .select-column {
    flex: 0 0 2%; }
  .transaction-row .date-column {
    flex: 0 0 12%; }
  .transaction-row .payee-column {
    flex: 0 0 16%; }
  .transaction-row .category-column {
    flex: 0 0 21%; }
  .transaction-row .notes-column {
    flex: 1; }
  .transaction-row .amount-column {
    flex: 0 0 8%;
    text-align: right;
    font-weight: 600;
    justify-content: flex-end; }
  .transaction-row .approved-column {
    justify-content: center;
    flex: 0 0 5%; }
    .transaction-row .approved-column i {
      color: #C9C9C9; }
      .transaction-row .approved-column i:hover {
        cursor: pointer;
        transition: 200ms all;
        color: #949AA5; }
    .transaction-row .approved-column i.approved {
      color: #26a65b; }
      .transaction-row .approved-column i.approved:hover {
        color: #26a65b !important; }
  .transaction-row .icon-toggle-split-children {
    margin-left: 4px;
    color: #04B5C9; }
    .transaction-row .icon-toggle-split-children:hover {
      cursor: pointer; }
  .transaction-row .child-collapsed > * {
    display: none !important; }
  .transaction-row .child-collapsed {
    display: none !important;
    padding: 0;
    margin-top: 0; }
  .transaction-row .parent-collapsed {
    margin-bottom: -0.75rem !important; }
  .transaction-row .split-remove-row-btn:hover {
    cursor: pointer; }

.transaction-row-desktop-inner-child {
  display: flex;
  background-color: #f3f3f3; }

.transaction-row-editable .transaction-row-desktop-inner-child {
  margin-left: -16px; }
  .transaction-row-editable .transaction-row-desktop-inner-child .remaining-column {
    text-align: right;
    justify-content: flex-end; }
    .transaction-row-editable .transaction-row-desktop-inner-child .remaining-column span {
      color: #52606D;
      font-weight: 600;
      display: block; }

.transaction-row-readonly {
  display: flex; }
  .transaction-row-readonly .transaction-column {
    padding-left: 4px; }

.uncategorized-row {
  border: 1px solid #ff9f1a !important; }

.transaction-row-editable, .row-selected {
  border: 1px solid #04B5C9;
  background-color: rgba(4, 181, 201, 0.1); }
  .transaction-row-editable:hover, .row-selected:hover {
    border: 1px solid #04B5C9;
    cursor: pointer; }
  .transaction-row-editable .amount-column .editable-cell-input-standalone, .row-selected .amount-column .editable-cell-input-standalone {
    text-align: right; }

.transaction-row-desktop-inner {
  width: 100%;
  height: 33px;
  display: flex;
  align-items: center; }
  .transaction-row-desktop-inner .editable-cell-input-standalone {
    background: #FFFFFF;
    width: 80%;
    border-radius: 4px;
    border-width: 0px;
    height: 1.3em;
    padding: 4px 4px 4px 16px;
    font-size: inherit; }
    .transaction-row-desktop-inner .editable-cell-input-standalone:focus {
      border: 2px solid #04B5C9; }
  .transaction-row-desktop-inner .DayPickerInput input {
    height: 1.3em;
    font-size: inherit;
    padding: 4px;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 4px;
    border-width: 0px; }
    .transaction-row-desktop-inner .DayPickerInput input:focus {
      border: 2px solid #04B5C9; }

.transaction-row-desktop-inner-child {
  padding: 4px 15px;
  background: #f3f3f3; }
  .transaction-row-desktop-inner-child:hover {
    cursor: pointer; }

.transaction-row-expanded-toolbar {
  display: block;
  text-align: right;
  background-color: #f7f7f7;
  width: 100%;
  margin: 0;
  padding: 8px 0;
  justify-content: flex-end;
  border: none; }
  .transaction-row-expanded-toolbar:hover {
    border: none;
    box-shadow: none;
    cursor: initial; }
  .transaction-row-expanded-toolbar button {
    margin-left: 8px; }
  .transaction-row-expanded-toolbar .button-primary {
    margin-right: 40px; }

.transaction-checkbox-container {
  position: relative;
  cursor: pointer;
  background-color: #333333;
  margin-right: 15px; }
  .transaction-checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
  .transaction-checkbox-container .transaction-checkmark {
    position: absolute;
    top: -7px;
    left: -5px;
    height: 14px;
    width: 14px;
    background-color: #D8D8D8; }

.transaction-checkbox-container:hover input ~ .transaction-checkmark {
  background-color: #CCCCCC; }

.transaction-checkbox-container input:checked ~ .transaction-checkmark {
  background-color: #4D9EC7; }

.transaction-checkmark::after {
  content: "";
  position: absolute;
  display: none; }

.transaction-checkbox-container input:checked ~ .transaction-checkmark:after {
  display: block; }

.transaction-checkbox-container .transaction-checkmark:after {
  left: 4px;
  top: 1px;
  width: 3px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.transaction-nil-amount, .transaction-nil-amount-inflow, .transaction-nil-amount-outflow {
  font-weight: 400 !important;
  color: #626e7a !important; }

.transaction-neg-amount, .transaction-neg-amount-inflow, .transaction-neg-amount-outflow {
  color: #C26E6C !important;
  font-weight: bold !important; }

.transaction-pos-amount, .transaction-pos-amount-inflow, .transaction-pos-amount-outflow {
  font-weight: bold !important; }

.editable-cell-input {
  background: #FFFFFF;
  width: 80%;
  border-radius: 4px; }
  .editable-cell-input .editable-cell-input__control {
    outline: none;
    height: 1.3em;
    border: 1px solid transparent;
    align-items: center;
    border-radius: 4px;
    justify-content: flex-start;
    line-height: 1.5;
    padding: 3px;
    position: static;
    vertical-align: middle;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .editable-cell-input .editable-cell-input__value-container {
    position: static;
    margin-top: -6px; }
  .editable-cell-input .editable-cell-input__single-value {
    width: 90%; }

.include-now-icon {
  margin-left: 12px;
  margin-right: -32px; }

i.include-now-icon-filled {
  margin-left: 12px;
  margin-right: -32px;
  color: #04B5C9 !important; }

i.moved-right {
  margin-right: -42px; }

[data-theme="dark"] .transaction-row-readonly {
  border-color: #585f6b;
  background-color: #343A42; }
  [data-theme="dark"] .transaction-row-readonly:hover {
    border-color: #767f8e;
    box-shadow: 0 2px 4px #1e1e1e; }
  [data-theme="dark"] .transaction-row-readonly .transaction-column {
    color: #B1B2B5; }

[data-theme="dark"] input[type="checkbox" i] {
  background-color: #262D34 !important; }
  [data-theme="dark"] input[type="checkbox" i] .editable-cell-input-standalone, [data-theme="dark"] input[type="checkbox" i] .editable-cell-input, [data-theme="dark"] input[type="checkbox" i] .DayPickerInput input, [data-theme="dark"] input[type="checkbox" i] .editable-cell-input__placeholder, [data-theme="dark"] input[type="checkbox" i] .editable-cell-input__single-value {
    background: #262D34;
    color: #DBDCDD; }

[data-theme="dark"] .transaction-row-editable, [data-theme="dark"] .row-selected {
  border: 1px solid #4D9EC7;
  background-color: rgba(4, 181, 201, 0.2); }
  [data-theme="dark"] .transaction-row-editable:hover, [data-theme="dark"] .row-selected:hover {
    border: 1px solid #4D9EC7;
    cursor: pointer; }
  [data-theme="dark"] .transaction-row-editable .transaction-column, [data-theme="dark"] .row-selected .transaction-column {
    color: #B1B2B5; }
  [data-theme="dark"] .transaction-row-editable .editable-cell-input-standalone, [data-theme="dark"] .transaction-row-editable .editable-cell-input, [data-theme="dark"] .transaction-row-editable .DayPickerInput input, [data-theme="dark"] .transaction-row-editable .editable-cell-input__placeholder, [data-theme="dark"] .transaction-row-editable .editable-cell-input__single-value, [data-theme="dark"] .row-selected .editable-cell-input-standalone, [data-theme="dark"] .row-selected .editable-cell-input, [data-theme="dark"] .row-selected .DayPickerInput input, [data-theme="dark"] .row-selected .editable-cell-input__placeholder, [data-theme="dark"] .row-selected .editable-cell-input__single-value {
    background: #262D34;
    color: #DBDCDD; }
  [data-theme="dark"] .transaction-row-editable .editable-cell-input__value-container *,
  [data-theme="dark"] .transaction-row-editable .editable-cell-input__value-container--hasValue *, [data-theme="dark"] .row-selected .editable-cell-input__value-container *,
  [data-theme="dark"] .row-selected .editable-cell-input__value-container--hasValue * {
    color: #DBDCDD !important; }
  [data-theme="dark"] .transaction-row-editable .editable-cell-input__menu, [data-theme="dark"] .row-selected .editable-cell-input__menu {
    background-color: #343A42; }
  [data-theme="dark"] .transaction-row-editable .editable-cell-input__option, [data-theme="dark"] .row-selected .editable-cell-input__option {
    color: #C9CBCD; }
  [data-theme="dark"] .transaction-row-editable .editable-cell-input__option--isFocused, [data-theme="dark"] .row-selected .editable-cell-input__option--isFocused {
    background-color: rgba(4, 181, 201, 0.2);
    color: #E4E7EB; }
  [data-theme="dark"] .transaction-row-editable .editable-cell-input__group-heading, [data-theme="dark"] .row-selected .editable-cell-input__group-heading {
    color: #04B5C9; }
  [data-theme="dark"] .transaction-row-editable .DayPickerInput-Overlay, [data-theme="dark"] .row-selected .DayPickerInput-Overlay {
    background-color: #343A42;
    color: #C9CBCD;
    box-shadow: rgba(30, 30, 30, 0.9) 0 4px 10px; }

[data-theme="dark"] .transaction-row-desktop-inner-child {
  background-color: #2E383F; }
  [data-theme="dark"] .transaction-row-desktop-inner-child .remaining-column span {
    color: #C9CBCD; }

[data-theme="dark"] .transaction-row-expanded-toolbar {
  background-color: #262D34; }

[data-theme="dark"] .approved-column i.approved {
  color: #2dda75; }
