@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
@media (min-width: 1024px) {
  .mobile-only {
    display: none; } }

@media (max-width: 1023px) {
  .mobile-hidden {
    display: none !important; } }

@media (max-width: 1199px) {
  .mobile-and-tablet-hidden {
    display: none !important; } }

@media (max-width: 640px) {
  .tablet-and-desktop-only {
    display: none !important; } }

@media (min-width: 641px) {
  .tablet-and-desktop-hidden {
    display: none !important; } }

@media (max-width: 1023px) {
  .desktop-only {
    display: none; } }

html * {
  font-family: 'Open Sans', sans-serif; }

html, body {
  font-family: 'Open Sans', sans-serif;
  background-color: #F7F7F7;
  font-size: 12px;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0; }
  html h1, html h2, html h3, html h4, body h1, body h2, body h3, body h4 {
    margin-block-start: 0;
    margin-block-end: 0; }
  html button:focus, body button:focus {
    outline: none; }

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus,
body:not(.user-is-tabbing) *:focus {
  outline: none;
  outline: -webkit-focus-ring-color auto 0px; }

.form-body {
  display: flex;
  width: 100%;
  flex-flow: column; }
  .form-body .input-select__indicator.input-select__dropdown-indicator {
    padding: 0 4px 0 0;
    color: #C9C9C9; }
  .form-body .input-select {
    min-width: 100%; }
    @media (min-width: 1024px) {
      .form-body .input-select {
        min-width: 110px; } }
  .form-body .input-select__menu {
    min-width: 90%; }
    @media (min-width: 1024px) {
      .form-body .input-select__menu {
        min-width: max-content; } }
    .form-body .input-select__menu * {
      font-size: 1rem; }
  @media (max-width: 1023px) {
    .form-body .input-select__option {
      padding: 8px 8px; } }
  .form-body .long-select.input-select {
    min-width: 160px; }
  .form-body .input-select__control {
    min-height: initial;
    border-color: #D2DCE5;
    background-color: #ffffff; }
    .form-body .input-select__control:hover {
      cursor: pointer;
      border-color: #D2DCE5; }
  .form-body .input-select__control--isFocused {
    transition: 200ms all;
    border: 1px solid #04B5C9;
    box-shadow: none; }
    .form-body .input-select__control--isFocused:hover {
      border-color: #04B5C9; }
  .form-body .form-controls-group {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px; }
    .form-body .form-controls-group button {
      margin-left: 8px; }
  .form-body .form-section {
    font-size: 1rem;
    color: #4D9EC7;
    font-weight: 700;
    display: inline-flex;
    margin-bottom: 6px; }
    @media (min-width: 1024px) {
      .form-body .form-section:not(:first-child) {
        margin-top: 16px; } }
  .form-body .form-group {
    display: flex;
    width: 100%;
    flex-flow: column;
    margin-bottom: 8px; }
  .form-body .form-column {
    flex-flow: column; }
    @media (min-width: 1024px) {
      .form-body .form-column {
        flex-flow: row; } }
  .form-body .form-split-column {
    margin-bottom: 16px; }
    @media (max-width: 1024px) {
      .form-body .form-split-column {
        display: flex;
        flex-flow: column; } }
    @media (min-width: 1024px) {
      .form-body .form-split-column {
        margin-bottom: 0; } }
    @media (min-width: 1024px) {
      .form-body .form-split-column:not(:last-child) {
        padding-right: 16px; } }

.input-label {
  font-weight: 600;
  font-size: 0.85rem;
  color: #52606D;
  flex: 1;
  display: flex;
  margin-bottom: 4px; }
  .input-label i {
    margin-left: 2px; }

.always-on-btn {
  opacity: 1 !important;
  visibility: visible !important; }

.input-field {
  padding: 8px 8px;
  background-color: #ffffff;
  color: #3E4C59;
  border-radius: 4px;
  border: 1px solid #D2DCE5;
  flex: 1; }
  @media (min-width: 1024px) {
    .input-field:focus, .input-field:active {
      transition: 200ms all;
      border-color: #04B5C9; } }

@media (max-width: 767px) {
  .DayPickerInput-Overlay {
    position: fixed; } }

@media (max-width: 767px) {
  .DayPickerInput {
    flex: 1 1;
    display: flex; } }

.transaction-row-desktop-inner .DayPickerInput .repeat-frequency {
  margin-top: 6px;
  margin-bottom: 4px; }

.transaction-row-desktop-inner .DayPickerInput .repeat-frequency-detail .is-horizontal {
  margin-top: 8px; }

.transaction-row-desktop-inner .DayPickerInput .left-field, .transaction-row-desktop-inner .DayPickerInput .right-field {
  display: inline-block; }

.transaction-row-desktop-inner .DayPickerInput input.input-field {
  padding: 8px 8px;
  background-color: #ffffff;
  color: #3E4C59;
  border-radius: 4px;
  max-width: 55px;
  margin: 0 8px;
  border: 1px solid #D2DCE5; }
  .transaction-row-desktop-inner .DayPickerInput input.input-field:focus, .transaction-row-desktop-inner .DayPickerInput input.input-field:active {
    transition: 200ms all;
    border-color: #04B5C9; }

.neg-val {
  color: #C26E6C !important;
  font-weight: bold !important; }

.centered-loading {
  color: #04B5C9;
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  font-size: 4rem;
  vertical-align: middle;
  transition: 300ms all; }

[aria-label]:after {
  color: #F5F7FA !important;
  --balloon-color: #3E4C59; }

[data-theme="dark"] html, body {
  background-color: #262D34; }

@media (min-width: 1024px) {
  main.inner-wrapper, .inner-wrapper {
    height: calc(100vh - 45px); } }

@media (min-width: 1200px) {
  main.inner-wrapper, .inner-wrapper {
    height: 100%;
    overflow-y: auto; } }

main.inner-wrapper .page-title, .inner-wrapper .page-title {
  color: #626e7a;
  display: inline-block;
  font-size: 1.3rem;
  margin-right: 8px;
  line-height: 1; }
  main.inner-wrapper .page-title:hover, .inner-wrapper .page-title:hover {
    cursor: default; }

[data-theme="dark"] main.inner-wrapper .page-title {
  color: #C9CBCD; }

[data-theme="dark"] .form-body .input-label {
  color: #DBDCDD; }

[data-theme="dark"] .form-body .input-field, [data-theme="dark"] .form-body .input-select__control {
  background-color: #262D34;
  border-color: #3C4149;
  color: #C9CBCD; }
  [data-theme="dark"] .form-body .input-field:focus, [data-theme="dark"] .form-body .input-select__control:focus {
    border-color: #31ACDA; }

[data-theme="dark"] .form-body .input-select__menu {
  background-color: #343A42; }

[data-theme="dark"] .form-body .input-select__option {
  color: #DBDCDD; }

[data-theme="dark"] .form-body .input-select__option--isFocused {
  background-color: #4D9EC7; }

[data-theme="dark"] .form-body .input-select__single-value {
  color: #C9CBCD; }

[data-theme="dark"] .form-body .form-section {
  color: #04B5C9; }

[data-theme="dark"] .form-body .editable-cell-input__menu {
  background-color: #343A42; }

[data-theme="dark"] .form-body .editable-cell-input__option {
  color: #C9CBCD; }

[data-theme="dark"] .form-body .editable-cell-input__option--isFocused {
  background-color: rgba(4, 181, 201, 0.2);
  color: #E4E7EB; }

[data-theme="dark"] .form-body .editable-cell-input__group-heading {
  color: #04B5C9; }

[data-theme="dark"] .form-body .DayPickerInput-Overlay {
  background-color: #343A42;
  color: #C9CBCD;
  box-shadow: rgba(30, 30, 30, 0.9) 0 4px 10px; }
