.ltb-loading {
  color: #04B5C9;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  width: 100%;
  font-size: 4rem;
  top: 30%;
  vertical-align: middle;
  transition: 300ms all; }

.ltb-desktop-month {
  width: 280px;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  position: fixed;
  right: -2px;
  top: 180px;
  font-size: 1rem;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: block; }
  .ltb-desktop-month .ltb-loading {
    color: #04B5C9;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    width: 100%;
    font-size: 4rem;
    top: 30%;
    vertical-align: middle;
    transition: 300ms all; }
  .ltb-desktop-month .ltb-wrapper {
    transition: 300ms all;
    padding: 16px 24px 16px 24px; }
  .ltb-desktop-month p.ltb-line-item {
    line-height: 1.7;
    display: flex;
    width: 100%;
    color: #616E7C;
    margin-block-start: 0;
    margin-block-end: 0; }
    .ltb-desktop-month p.ltb-line-item .ltb-amount {
      flex: 1;
      color: #626e7a; }
  .ltb-desktop-month p.left-to-budget-line-item {
    color: #52606D;
    margin-top: 12px;
    font-weight: 600;
    align-items: center; }
    .ltb-desktop-month p.left-to-budget-line-item .ltb-amount {
      color: #52606D;
      font-size: 1.5rem;
      font-weight: 700; }
  .ltb-desktop-month .ltb-amount {
    text-align: right;
    font-weight: 700; }

[data-theme="dark"] .ltb-desktop-month {
  background-color: #343A42;
  border-color: #2E383F;
  box-shadow: #1e1e1e 0 0 4px; }
  [data-theme="dark"] .ltb-desktop-month p.ltb-line-item {
    color: #949AA5; }
    [data-theme="dark"] .ltb-desktop-month p.ltb-line-item .ltb-amount {
      color: #C9CBCD; }
  [data-theme="dark"] .ltb-desktop-month p.left-to-budget-line-item {
    color: #C9CBCD; }
    [data-theme="dark"] .ltb-desktop-month p.left-to-budget-line-item .ltb-amount {
      color: #C9CBCD; }
