.mobile-category-add {
  font-size: 1.2rem !important;
  z-index: 19;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 16px 0 !important;
  border-radius: 0 !important; }

.budget-header-row {
  display: block;
  margin-top: 4px;
  margin-bottom: -4px; }
  @media (min-width: 1024px) {
    .budget-header-row {
      display: flex;
      align-items: center;
      flex-flow: row;
      width: 100%; } }
  .budget-header-row .budget-header-column {
    width: 100%; }
  .budget-header-row .month-view-toggle {
    display: inline-block; }
    .budget-header-row .month-view-toggle .toggle-item {
      color: #52606D;
      font-size: 1rem;
      font-weight: 600;
      border-radius: 12px;
      padding: 4px 8px;
      margin-right: 4px; }
      .budget-header-row .month-view-toggle .toggle-item:hover {
        cursor: pointer; }
    .budget-header-row .month-view-toggle .is-toggled {
      background-color: #E4E7EB; }
  .budget-header-row .single-view-month-notes {
    color: #626e7a;
    margin-left: 8px;
    transition: 0.3s all;
    opacity: 0; }
    .budget-header-row .single-view-month-notes:hover {
      cursor: pointer; }
  .budget-header-row .date-selector-wrapper {
    margin-top: 8px;
    margin-bottom: -10px;
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    @media (min-width: 1024px) {
      .budget-header-row .date-selector-wrapper {
        margin-top: 0;
        margin-bottom: 0;
        justify-content: center; } }
    .budget-header-row .date-selector-wrapper:hover .single-view-month-notes {
      transition: 0.3s all;
      opacity: 1; }
  .budget-header-row .date-selector {
    display: flex;
    align-content: center; }
    .budget-header-row .date-selector .date-previous, .budget-header-row .date-selector .date-next {
      width: 26px;
      height: 27px;
      display: inline-flex;
      justify-content: center;
      background-color: #FFF;
      border: 1px solid #DFE1E3;
      border-radius: 4px;
      box-shadow: rgba(223, 225, 227, 0.7) 0 0 4px; }
      .budget-header-row .date-selector .date-previous:hover, .budget-header-row .date-selector .date-next:hover {
        background-color: #fcfcfc;
        box-shadow: none; }
      .budget-header-row .date-selector .date-previous i, .budget-header-row .date-selector .date-next i {
        color: #31ACDA; }
        .budget-header-row .date-selector .date-previous i:hover, .budget-header-row .date-selector .date-next i:hover {
          cursor: pointer; }
  .budget-header-row .controls-wrapper {
    display: flex;
    justify-content: flex-end; }
    .budget-header-row .controls-wrapper button {
      margin-left: 8px; }
  .budget-header-row .date-title {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 65px;
    color: #626e7a;
    font-weight: 600;
    font-size: 1.3rem;
    margin: 0 8px;
    text-align: center; }
    @media (min-width: 1024px) {
      .budget-header-row .date-title {
        display: inline-block;
        font-size: 1.5rem;
        min-width: 125px; } }

[data-theme="dark"] .budget-header-row .month-view-toggle .toggle-item {
  color: #949AA5; }

[data-theme="dark"] .budget-header-row .month-view-toggle .is-toggled {
  background-color: #343A42; }

[data-theme="dark"] .budget-header-row .date-selector .date-previous, [data-theme="dark"] .budget-header-row .date-selector .date-next {
  box-shadow: #1e1e1e 0 0 4px;
  background-color: #2E383F;
  border-color: #2E383F; }
  [data-theme="dark"] .budget-header-row .date-selector .date-previous:hover, [data-theme="dark"] .budget-header-row .date-selector .date-next:hover {
    box-shadow: none; }

[data-theme="dark"] .budget-header-row .date-title {
  color: #C9CBCD; }
