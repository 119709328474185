// COLORS

// ====== LIGHT THEME ====== //
$primary: #04B5C9;
$primary-dark: #31ACDA;
$primary-darkmode: #4D9EC7;
$secondary: #8ca0af;
$secondary-darkmode: #627b8e;
$dull-gray: #C9C9C9;
$green: #26a65b;
$green-darkmode: #2dda75;
$red: #C26E6C;
$warning: #DA7A7A;
$orange: #f0932b;
$uncategorized: #ff9f1a;
$header-background-color: #FFFFFF;
$body-background-color: #F7F7F7;
$border-color: #D2DCE5;
$border-color-darker: #C9C9C9;
$dropdown-divider-color: #dbdbdb;
$border-color-light: #DFE1E3;
$border-color-lighter: #E8EAEE;
$box-shadow-light: rgba(223, 225, 227, .7) 0 0 4px;


// ====== DARK THEME ====== //
$dark-background-dark-mode: #2E383F;
$light-background-darkmode: #343A42;
$body-background-color-darkmode: #262D34;
$dropdown-divider-color-darkmode: #949AA5;
$border-color-dark-darkmode: #3C4149;
$box-shadow-darkmode: rgba(30, 30, 30, 1) 0 0 4px;
$blue-highlight-darkmode: #2FBFC4;
$border-color-lighter-darkmode: #3C4149;

// GLOBAL TYPOGRAPHY
$text-lightest: #FDFEFE;
$text-white: #F5F7FA;
$text-light: #616E7C;
$text-light-gray: #E4E7EB;
$text-dark: #626e7a;
$text-darker: #52606D;
$text-darkest: #3E4C59;

$text-lighter-darkmode: #DBDCDD;
$text-light-darkmode: #C9CBCD;
$text-dark-darkmode: #949AA5;
$text-darker-darkmode: #B1B2B5;


// FONT SIZES
$small-text: 0.85rem;
$substandard-text: 0.9rem;
$standard-text: 1rem;
$superstandard-text: 1.1rem;
$sidebar-links: 1.1rem;
$sidebar-links-mobile: 1.3rem;
$header: 1.2rem;
$header-large: 1.3rem;
$header-extra-large: 1.5rem;
$header-xxl: 2rem;
$header-xxxl: 3rem;

// HEIGHTS AND WIDTHS
$header-height: 35px;
$sidebar-width: 275px;

$double-gutter-width: 32px;
$full-gutter-width: 16px;
$half-gutter-width: 8px;
$quarter-gutter-width: 4px;

$button-radius: 4px;

// SIDEBAR
$sidebar-background: #343D49;
$sidebar-background-darkmode: #22272A;
$sidebar-links-darkmode: #949AA5;
$sidebar-dropdown-links-darkmode: #C9CBCD;
$sidebar-account-section-header: #43D0E2;
$sidebar-section-divider: #DFE1E3;
