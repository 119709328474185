@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
button.button-base {
  font-size: 1rem;
  background-color: #FFFFFF;
  border: 1px solid #DFE1E3;
  color: #52606D;
  border-radius: 4px;
  padding: 5px 8px;
  font-weight: 600;
  letter-spacing: 1;
  box-shadow: rgba(223, 225, 227, 0.7) 0 0 4px; }
  button.button-base:hover {
    cursor: pointer; }
  button.button-base:focus {
    box-shadow: none; }
  button.button-base .button-icon {
    margin-right: 4px; }

button.is-disabled {
  opacity: 0.6;
  color: #B1B2B5;
  box-shadow: none !important;
  border-color: rgba(0, 0, 0, 0) !important; }
  button.is-disabled:hover {
    cursor: initial !important; }

[data-theme="dark"] button.button-base {
  background-color: #343A42;
  border-color: #343A42;
  color: #C9CBCD;
  box-shadow: #1e1e1e 0 0 4px; }

button.button-primary {
  background-color: #04B5C9;
  border: 1px solid #04B5C9;
  color: #FDFEFE; }
  button.button-primary:hover {
    background-color: #04acbf;
    cursor: pointer; }
  button.button-primary:active {
    background-color: #04a3b5; }

[data-theme="dark"] button.button-primary {
  background-color: #4D9EC7;
  border-color: #4D9EC7;
  color: #FDFEFE; }
  [data-theme="dark"] button.button-primary:hover {
    background-color: #3c93bf;
    border-color: #3c93bf;
    cursor: pointer; }
  [data-theme="dark"] button.button-primary:active {
    background-color: #3a8db7;
    border-color: #3a8db7; }

.mobile-category-add {
  font-size: 1.2rem !important;
  z-index: 19;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 16px 0 !important;
  border-radius: 0 !important; }

.budget-header-row {
  display: block;
  margin-top: 4px;
  margin-bottom: -4px; }
  @media (min-width: 1024px) {
    .budget-header-row {
      display: flex;
      align-items: center;
      flex-flow: row;
      width: 100%; } }
  .budget-header-row .budget-header-column {
    width: 100%; }
  .budget-header-row .month-view-toggle {
    display: inline-block; }
    .budget-header-row .month-view-toggle .toggle-item {
      color: #52606D;
      font-size: 1rem;
      font-weight: 600;
      border-radius: 12px;
      padding: 4px 8px;
      margin-right: 4px; }
      .budget-header-row .month-view-toggle .toggle-item:hover {
        cursor: pointer; }
    .budget-header-row .month-view-toggle .is-toggled {
      background-color: #E4E7EB; }
  .budget-header-row .single-view-month-notes {
    color: #626e7a;
    margin-left: 8px;
    transition: 0.3s all;
    opacity: 0; }
    .budget-header-row .single-view-month-notes:hover {
      cursor: pointer; }
  .budget-header-row .date-selector-wrapper {
    margin-top: 8px;
    margin-bottom: -10px;
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    @media (min-width: 1024px) {
      .budget-header-row .date-selector-wrapper {
        margin-top: 0;
        margin-bottom: 0;
        justify-content: center; } }
    .budget-header-row .date-selector-wrapper:hover .single-view-month-notes {
      transition: 0.3s all;
      opacity: 1; }
  .budget-header-row .date-selector {
    display: flex;
    align-content: center; }
    .budget-header-row .date-selector .date-previous, .budget-header-row .date-selector .date-next {
      width: 26px;
      height: 27px;
      display: inline-flex;
      justify-content: center;
      background-color: #FFF;
      border: 1px solid #DFE1E3;
      border-radius: 4px;
      box-shadow: rgba(223, 225, 227, 0.7) 0 0 4px; }
      .budget-header-row .date-selector .date-previous:hover, .budget-header-row .date-selector .date-next:hover {
        background-color: #fcfcfc;
        box-shadow: none; }
      .budget-header-row .date-selector .date-previous i, .budget-header-row .date-selector .date-next i {
        color: #31ACDA; }
        .budget-header-row .date-selector .date-previous i:hover, .budget-header-row .date-selector .date-next i:hover {
          cursor: pointer; }
  .budget-header-row .controls-wrapper {
    display: flex;
    justify-content: flex-end; }
    .budget-header-row .controls-wrapper button {
      margin-left: 8px; }
  .budget-header-row .date-title {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 65px;
    color: #626e7a;
    font-weight: 600;
    font-size: 1.3rem;
    margin: 0 8px;
    text-align: center; }
    @media (min-width: 1024px) {
      .budget-header-row .date-title {
        display: inline-block;
        font-size: 1.5rem;
        min-width: 125px; } }

[data-theme="dark"] .budget-header-row .month-view-toggle .toggle-item {
  color: #949AA5; }

[data-theme="dark"] .budget-header-row .month-view-toggle .is-toggled {
  background-color: #343A42; }

[data-theme="dark"] .budget-header-row .date-selector .date-previous, [data-theme="dark"] .budget-header-row .date-selector .date-next {
  box-shadow: #1e1e1e 0 0 4px;
  background-color: #2E383F;
  border-color: #2E383F; }
  [data-theme="dark"] .budget-header-row .date-selector .date-previous:hover, [data-theme="dark"] .budget-header-row .date-selector .date-next:hover {
    box-shadow: none; }

[data-theme="dark"] .budget-header-row .date-title {
  color: #C9CBCD; }

.standard-editable-input {
  background: none;
  border-radius: 4px;
  height: -webkit-min-content;
  height: min-content;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 2px 4px;
  font-size: inherit; }
  .standard-editable-input:hover {
    border-bottom: 1px dotted #949AA5; }
  .standard-editable-input:focus {
    background: #FFFFFF;
    border: 1px solid #04B5C9; }

[data-theme="dark"] .standard-editable-input {
  color: #C9CBCD; }

[data-theme="dark"] .standard-editable-input.is-editing {
  background-color: #343A42; }

button.button-link {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  box-shadow: none;
  color: #626e7a; }
  button.button-link:hover {
    background-color: #eaeaea;
    cursor: pointer; }
  button.button-link:active {
    background-color: #e5e5e5; }

[data-theme="dark"] button.button-link {
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  box-shadow: none; }
  [data-theme="dark"] button.button-link:hover {
    background-color: #20252b;
    border-color: #20252b;
    cursor: pointer; }
  [data-theme="dark"] button.button-link:active {
    background-color: #1b2025;
    border-color: #1b2025; }

.move-money-popover-container {
  overflow: visible !important; }

.move-money-popover {
  background: white;
  border: 1px solid #04B5C9;
  border-radius: 6px;
  padding: 8px 16px; }
  .move-money-popover .move-money-header {
    display: flex;
    align-items: center;
    padding-bottom: 8px; }
    .move-money-popover .move-money-header .move-money-title {
      font-size: 1rem;
      font-weight: bold;
      flex: 1 1; }
    .move-money-popover .move-money-header .move-money-row-add-delete {
      margin-left: 16px; }
      .move-money-popover .move-money-header .move-money-row-add-delete .button-link {
        padding: 4px;
        margin-left: 4px; }
  .move-money-popover .move-money-category-row {
    display: flex;
    margin-bottom: 4px; }
    .move-money-popover .move-money-category-row .editable-cell-input {
      width: 160px;
      margin-right: 8px;
      border-radius: 4px;
      border: 1px solid #D2DCE5; }
    .move-money-popover .move-money-category-row .editable-cell-input__value-container {
      position: relative; }
    .move-money-popover .move-money-category-row .editable-cell-input__control--isFocused {
      border-color: #04B5C9; }
    .move-money-popover .move-money-category-row .input-field {
      text-align: right;
      width: 50px;
      font-size: 1rem; }
  .move-money-popover .budget-category-input-editable {
    border: 1px solid #DFE1E3;
    border-radius: 4px;
    color: #626e7a;
    font-size: inherit;
    font-weight: 400;
    padding: 0 4px;
    text-align: right;
    max-width: 75px; }
    .move-money-popover .budget-category-input-editable:hover {
      transition: 250ms all;
      border: 1px solid #DFE1E3; }
    .move-money-popover .budget-category-input-editable:focus {
      box-shadow: 0 0 0 1px #04B5C9; }

.react-confirm-alert-overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.3) !important;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.01s 0.01s forwards !important;
  animation: react-confirm-alert-fadeIn 0.01s 0.01s forwards !important; }

.react-confirm-alert-blur {
  transition: 100ms all;
  filter: none !important;
  -webkit-filter: none !important; }

.modal-base {
  min-width: 305px;
  background-color: #FFFFFF;
  box-shadow: rgba(223, 225, 227, 0.7) 0 0 4px;
  border-radius: 8px;
  padding-bottom: 16px; }
  @media (max-width: 640px) {
    .modal-base {
      height: 100vh;
      width: 100vw;
      position: fixed;
      left: 0;
      top: 0; } }
  @media (min-width: 375px) {
    .modal-base {
      min-width: 345px; } }
  @media (min-width: 1024px) {
    .modal-base {
      min-width: 310px; } }
  @media (min-width: 1440px) {
    .modal-base {
      margin-right: -210px; } }
  @media (min-width: 1600px) {
    .modal-base {
      margin-right: -180px; } }
  .modal-base .modal-header {
    padding: 12px 16px;
    display: flex;
    border-bottom: 1px solid #E8EAEE;
    align-items: center; }
    .modal-base .modal-header .modal-title {
      display: inline-block;
      flex: 1 1;
      font-size: 1.3rem;
      color: #626e7a;
      margin-right: 32px;
      opacity: 0.7; }
      .modal-base .modal-header .modal-title:hover {
        transition: 150ms all;
        opacity: 1;
        cursor: pointer; }
  .modal-base .modal-body {
    height: 80vh;
    display: flex;
    padding: 16px;
    overflow-y: auto;
    overflow-x: hidden; }
    @media (min-width: 1024px) {
      .modal-base .modal-body {
        height: 100%;
        overflow: initial;
        max-height: none; } }
  .modal-base .input-field {
    width: 100%; }

.new-transaction-modal .form-group {
  justify-content: space-between;
  flex-flow: row !important; }

@media (min-width: 1024px) {
  .new-transaction-modal input {
    width: 138px; } }

.new-transaction-modal .input-label {
  align-items: center;
  flex: 0 0 50%; }

.new-transaction-modal .editable-cell-input {
  border: 1px solid #D2DCE5;
  border-radius: 4px;
  padding: 4px 8px; }
  .new-transaction-modal .editable-cell-input .editable-cell-input__control {
    padding-left: 0; }

.new-transaction-modal .editable-cell-input__value-container {
  padding-left: 0; }

.new-transfer-modal .DayPickerInput {
  display: flex;
  width: 100%; }

.new-transfer-modal .form-group {
  justify-content: space-between;
  flex-flow: row !important; }

.new-transfer-modal .input-label {
  align-items: center;
  flex: 0 0 50%; }

.new-transfer-modal .editable-cell-input {
  border: 1px solid #D2DCE5;
  border-radius: 4px;
  padding: 4px 8px; }
  .new-transfer-modal .editable-cell-input .editable-cell-input__control {
    padding-left: 0; }

.new-transfer-modal .editable-cell-input__value-container {
  padding-left: 0; }

.expense-income-switch {
  display: flex;
  justify-content: center;
  margin-bottom: 16px; }
  .expense-income-switch .selectable-pill {
    width: 45%;
    text-align: center;
    display: inline-block;
    flex: 0 1;
    padding: 8px 35px;
    font-weight: 500;
    border: 2px solid transparent;
    margin: 0 8px; }
  .expense-income-switch .outflow-selected {
    border: 2px solid #de7f40;
    border-radius: 20px;
    background-color: #de7f40;
    color: #fdfefe;
    font-weight: 600; }
  .expense-income-switch .inflow-selected {
    border: 2px solid #26a65b;
    border-radius: 20px;
    background-color: #26a65b;
    color: #fdfefe;
    font-weight: 600; }

.recurring-cancel {
  max-width: 450px; }
  .recurring-cancel .alert-icon {
    margin-right: 6px; }
  .recurring-cancel .alert-title {
    display: block;
    font-size: 1.1rem;
    color: #3E4C59;
    font-weight: 600;
    margin-bottom: 8px; }
  .recurring-cancel .alert-text {
    display: block;
    margin-bottom: 16px;
    color: #52606D; }
  .recurring-cancel .alert-button-container {
    text-align: right; }
    .recurring-cancel .alert-button-container button {
      margin-left: 16px; }

[data-theme="dark"] .modal-base {
  background-color: #343A42;
  box-shadow: #1e1e1e 0 0 4px; }
  [data-theme="dark"] .modal-base .alert-title, [data-theme="dark"] .modal-base .alert-text {
    color: #C9CBCD; }
  [data-theme="dark"] .modal-base .modal-header {
    border-bottom: 1px solid #262D34; }
    [data-theme="dark"] .modal-base .modal-header .modal-title, [data-theme="dark"] .modal-base .modal-header i {
      color: #C9CBCD; }
  [data-theme="dark"] .modal-base .modal-body {
    background-color: #2E383F; }
    [data-theme="dark"] .modal-base .modal-body .expense-income-switch .selectable-pill {
      color: #DBDCDD; }
    [data-theme="dark"] .modal-base .modal-body .expense-income-switch .inflow-selected, [data-theme="dark"] .modal-base .modal-body .expense-income-switch .outflow-selected {
      color: #FDFEFE; }
  [data-theme="dark"] .modal-base .editable-cell-input {
    border-color: #3C4149; }
  [data-theme="dark"] .modal-base .editable-cell-input-standalone, [data-theme="dark"] .modal-base .editable-cell-input, [data-theme="dark"] .modal-base .DayPickerInput input, [data-theme="dark"] .modal-base .editable-cell-input__placeholder, [data-theme="dark"] .modal-base .editable-cell-input__single-value {
    background: #262D34;
    color: #DBDCDD; }
  [data-theme="dark"] .modal-base .editable-cell-input-standalone, [data-theme="dark"] .modal-base .editable-cell-input, [data-theme="dark"] .modal-base .DayPickerInput input, [data-theme="dark"] .modal-base .editable-cell-input__placeholder, [data-theme="dark"] .modal-base .editable-cell-input__single-value {
    background: #262D34;
    color: #DBDCDD; }
  [data-theme="dark"] .modal-base .editable-cell-input__value-container--hasValue * {
    color: #DBDCDD !important; }


button.button-secondary {
  background-color: #8ca0af;
  border: 1px solid #8ca0af;
  color: #FDFEFE; }
  button.button-secondary:hover {
    background-color: #869bab;
    cursor: pointer; }
  button.button-secondary:active {
    background-color: #8096a7; }

[data-theme="dark"] button.button-secondary {
  background-color: #627b8e;
  border-color: #627b8e;
  color: #FDFEFE; }
  [data-theme="dark"] button.button-secondary:hover {
    background-color: #586e7f;
    border-color: #586e7f;
    cursor: pointer; }
  [data-theme="dark"] button.button-secondary:active {
    background-color: #536979;
    border-color: #536979; }

.category-activity-table {
  width: 100%;
  color: #626e7a; }
  .category-activity-table table {
    min-width: 450px;
    width: 100%;
    border-collapse: collapse;
    max-height: 280px; }
  .category-activity-table thead {
    border-bottom: 1px solid #31ACDA; }
  .category-activity-table tbody {
    overflow: auto; }
    .category-activity-table tbody tr:nth-child(even) {
      background: #f7f7f7; }
      .category-activity-table tbody tr:nth-child(even) td {
        background: #f7f7f7; }
    .category-activity-table tbody .account-column {
      font-weight: 600;
      color: #52606D; }
  .category-activity-table .date-column, .category-activity-table .account-column, .category-activity-table .payee-column, .category-activity-table .notes-column {
    padding: 6px 8px;
    padding-right: 16px;
    text-align: left; }
  .category-activity-table .amount-column {
    padding: 6px 8px;
    padding-left: 32px;
    text-align: right;
    font-weight: 600;
    color: #52606D; }

[data-theme="dark"] .category-activity-table thead {
  color: #DBDCDD; }
  [data-theme="dark"] .category-activity-table thead tr {
    border-bottom: 1px solid #2FBFC4; }
    [data-theme="dark"] .category-activity-table thead tr td {
      padding-bottom: 8px; }
    [data-theme="dark"] .category-activity-table thead tr .amount-column {
      color: #DBDCDD; }

[data-theme="dark"] .category-activity-table tbody {
  color: #C9CBCD; }
  [data-theme="dark"] .category-activity-table tbody tr:nth-child(even) {
    background: #262D34; }
    [data-theme="dark"] .category-activity-table tbody tr:nth-child(even) td {
      background: #262D34; }
  [data-theme="dark"] .category-activity-table tbody .account-column {
    font-weight: 600;
    color: #31ACDA; }
  [data-theme="dark"] .category-activity-table tbody .amount-column {
    color: #DBDCDD; }

.ltb-loading {
  color: #04B5C9;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  width: 100%;
  font-size: 4rem;
  top: 30%;
  vertical-align: middle;
  transition: 300ms all; }

.ltb-desktop-month {
  width: 280px;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  position: fixed;
  right: -2px;
  top: 180px;
  font-size: 1rem;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: block; }
  .ltb-desktop-month .ltb-loading {
    color: #04B5C9;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    width: 100%;
    font-size: 4rem;
    top: 30%;
    vertical-align: middle;
    transition: 300ms all; }
  .ltb-desktop-month .ltb-wrapper {
    transition: 300ms all;
    padding: 16px 24px 16px 24px; }
  .ltb-desktop-month p.ltb-line-item {
    line-height: 1.7;
    display: flex;
    width: 100%;
    color: #616E7C;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0; }
    .ltb-desktop-month p.ltb-line-item .ltb-amount {
      flex: 1 1;
      color: #626e7a; }
  .ltb-desktop-month p.left-to-budget-line-item {
    color: #52606D;
    margin-top: 12px;
    font-weight: 600;
    align-items: center; }
    .ltb-desktop-month p.left-to-budget-line-item .ltb-amount {
      color: #52606D;
      font-size: 1.5rem;
      font-weight: 700; }
  .ltb-desktop-month .ltb-amount {
    text-align: right;
    font-weight: 700; }

[data-theme="dark"] .ltb-desktop-month {
  background-color: #343A42;
  border-color: #2E383F;
  box-shadow: #1e1e1e 0 0 4px; }
  [data-theme="dark"] .ltb-desktop-month p.ltb-line-item {
    color: #949AA5; }
    [data-theme="dark"] .ltb-desktop-month p.ltb-line-item .ltb-amount {
      color: #C9CBCD; }
  [data-theme="dark"] .ltb-desktop-month p.left-to-budget-line-item {
    color: #C9CBCD; }
    [data-theme="dark"] .ltb-desktop-month p.left-to-budget-line-item .ltb-amount {
      color: #C9CBCD; }

.ltb-tablet-month {
  display: flex;
  flex: 1 1;
  color: #616E7C;
  margin: 0 16px 32px; }
  .ltb-tablet-month .ltb-wrapper {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
    align-content: space-between; }
  .ltb-tablet-month .ltb-loading {
    color: #04B5C9;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    width: 100%;
    font-size: 3rem;
    vertical-align: middle;
    transition: 300ms all; }
  .ltb-tablet-month .ltb-section {
    border-radius: 4px;
    padding: 8px 16px;
    min-width: 190px;
    background: #FFFFFF; }
    .ltb-tablet-month .ltb-section p.ltb-line-item {
      display: flex;
      -webkit-margin-before: 0;
              margin-block-start: 0;
      -webkit-margin-after: 0;
              margin-block-end: 0; }
    .ltb-tablet-month .ltb-section .ltb-amount {
      flex: 1 1;
      font-weight: 700;
      display: flex;
      justify-content: flex-end;
      color: #626e7a; }
  .ltb-tablet-month .left-to-budget-section {
    min-width: initial; }
    .ltb-tablet-month .left-to-budget-section p.left-to-budget-line-item {
      display: block; }
      .ltb-tablet-month .left-to-budget-section p.left-to-budget-line-item span {
        display: block;
        text-align: right; }
      .ltb-tablet-month .left-to-budget-section p.left-to-budget-line-item .ltb-amount {
        display: block;
        font-size: 1.3rem;
        color: #52606D; }

[data-theme="dark"] .ltb-tablet-month {
  color: #949AA5; }
  [data-theme="dark"] .ltb-tablet-month .ltb-section {
    background-color: #343A42; }
    [data-theme="dark"] .ltb-tablet-month .ltb-section .ltb-amount {
      color: #C9CBCD; }

.ltb-mobile-month {
  position: absolute;
  right: 16px;
  top: 45px; }
  .ltb-mobile-month .left-to-budget-line-item span {
    display: block;
    font-size: 0.9rem;
    text-align: right;
    color: #616E7C;
    font-weight: 600; }
  .ltb-mobile-month .left-to-budget-line-item .ltb-amount {
    font-size: 1.3rem;
    font-weight: 700;
    color: #52606D; }
  .ltb-mobile-month .ltb-loading {
    color: #04B5C9;
    width: 100%;
    display: inline-block;
    margin-top: 12px;
    margin-right: 16px;
    font-size: 2rem;
    transition: 300ms all; }

[data-theme="dark"] .ltb-mobile-month .left-to-budget-line-item span {
  font-weight: 600;
  color: #B1B2B5; }

[data-theme="dark"] .ltb-mobile-month .left-to-budget-line-item .ltb-amount {
  color: #C9CBCD;
  font-weight: 700; }

.empty-state-msg-budget {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #B1B2B5;
  font-weight: bold;
  text-align: center;
  margin-top: 50px;
  line-height: 1.3; }
  @media (min-width: 768px) {
    .empty-state-msg-budget {
      line-height: 1;
      margin-top: 80px;
      font-size: 3rem; } }

.underlined:hover {
  text-decoration: underline; }

.uncategorized-icon {
  color: #ff9f1a; }

.category-is-dragging {
  min-height: 22px;
  border: 1px dashed #31ACDA !important;
  border-radius: 2px;
  box-shadow: rgba(223, 225, 227, 0.7) 0 0 4px !important;
  margin: 4px; }

.category-group-is-dragging {
  border: 1px dashed #31ACDA !important;
  border-radius: 2px;
  box-shadow: rgba(223, 225, 227, 0.7) 0 0 4px !important;
  margin: 8px; }

.category-dropdown-toggle {
  position: absolute;
  width: 22px;
  text-align: center;
  right: 5px; }

.copy-last-month-budget-icon {
  color: #31ACDA;
  position: absolute !important;
  opacity: 0.6;
  transition: 0.25s ease-in-out; }
  @media (min-width: 768px) {
    .copy-last-month-budget-icon {
      display: none; }
      .copy-last-month-budget-icon:hover {
        cursor: pointer;
        transition: 0.25s ease-in-out;
        opacity: 1; } }

.budget-input-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center; }
  .budget-input-wrapper:hover .copy-last-month-budget-icon {
    display: initial; }

.budget-table-wrapper .budget-table-row .budget-column-name input {
  width: 69%; }
  @media (min-width: 375px) {
    .budget-table-wrapper .budget-table-row .budget-column-name input {
      width: 75%; } }
  @media (min-width: 414px) {
    .budget-table-wrapper .budget-table-row .budget-column-name input {
      width: inherit; } }

@media (max-width: 640px) {
  .budget-table-wrapper .budget-table-row .budget-column-name .add-notes-button {
    margin-left: 0; } }

.category-dropdown-menu {
  display: none;
  padding: 4px 0;
  margin: 5px 0 0;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  z-index: 100;
  position: absolute;
  list-style: none;
  background-color: #fff;
  text-align: left;
  list-style: none;
  float: left;
  left: auto;
  right: -8px;
  top: 25px; }
  .category-dropdown-menu:before {
    position: absolute;
    top: -11px;
    right: 8px;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 11px;
    border-color: transparent transparent #d8d8d8 transparent; }
  .category-dropdown-menu:after {
    position: absolute;
    top: -9px;
    right: 9px;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 10px;
    border-color: transparent transparent #FFFFFF transparent; }

.category-dropdown-size {
  min-width: 150px; }

.category-group-dropdown-size {
  min-width: 185px; }

.category-dropdown-open {
  display: block; }
  .category-dropdown-open li {
    padding: 6px 8px;
    font-weight: 600;
    color: #626e7a; }
    .category-dropdown-open li i {
      margin-right: 8px; }
    .category-dropdown-open li .is-warning {
      color: #DA7A7A; }
    .category-dropdown-open li:hover {
      background-color: rgba(8, 180, 201, 0.1); }

.dropdown-backdrop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0; }
  .dropdown-backdrop:hover {
    cursor: initial; }

.category-dropdown-toggle .dropdown-backdrop {
  z-index: 90; }

.budget-table-wrapper {
  margin: 32px 0;
  padding: 0;
  height: 70vh; }
  @media (min-width: 375px) {
    .budget-table-wrapper {
      height: 74vh; } }
  @media (min-width: 414px) {
    .budget-table-wrapper {
      height: 76vh; } }
  @media (min-width: 768px) {
    .budget-table-wrapper {
      height: 81vh; } }
  @media (max-width: 1023px) {
    .budget-table-wrapper {
      overflow: hidden; } }
  @media (min-width: 1024px) {
    .budget-table-wrapper {
      padding: 0;
      display: flex;
      flex-flow: column;
      height: 87vh; } }
  @media (min-width: 1440px) {
    .budget-table-wrapper {
      max-width: 75%; } }
  .budget-table-wrapper .budget-table-row {
    display: flex;
    flex-direction: row;
    position: relative;
    border-bottom: 1px solid #DFE1E3;
    padding: 7px 12px; }
    @media (min-width: 1024px) {
      .budget-table-wrapper .budget-table-row {
        padding: 4px 40px 4px 8px; } }
    @media (min-width: 1440px) {
      .budget-table-wrapper .budget-table-row {
        padding: 7px 40px 7px 20px; } }
    .budget-table-wrapper .budget-table-row .editing {
      opacity: 0.75;
      padding: 0 4px;
      border-bottom: 1px dashed #04cce2;
      margin-left: -4px;
      margin-right: -4px; }
    .budget-table-wrapper .budget-table-row .budget-column-name {
      flex: 1 1; }
      .budget-table-wrapper .budget-table-row .budget-column-name i {
        margin-left: 8px;
        color: #04B5C9; }
        .budget-table-wrapper .budget-table-row .budget-column-name i:hover {
          color: #049eb0; }
    .budget-table-wrapper .budget-table-row .budget-column-date, .budget-table-wrapper .budget-table-row .budget-column-budgeted, .budget-table-wrapper .budget-table-row .budget-column-activity, .budget-table-wrapper .budget-table-row .budget-column-remaining {
      flex: 0 0 27%; }
      @media (min-width: 1024px) {
        .budget-table-wrapper .budget-table-row .budget-column-date, .budget-table-wrapper .budget-table-row .budget-column-budgeted, .budget-table-wrapper .budget-table-row .budget-column-activity, .budget-table-wrapper .budget-table-row .budget-column-remaining {
          flex: 0 0 16.5%; } }
    .budget-table-wrapper .budget-table-row .budget-column-date {
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex; }
    .budget-table-wrapper .budget-table-row .budget-column-activity, .budget-table-wrapper .budget-table-row .budget-column-remaining, .budget-table-wrapper .budget-table-row .budget-column-budgeted {
      text-align: right; }
    .budget-table-wrapper .budget-table-row .due-date__value-container {
      justify-content: center;
      padding: 0; }
      .budget-table-wrapper .budget-table-row .due-date__value-container div {
        padding: 0;
        margin-left: -1px; }
    .budget-table-wrapper .budget-table-row .due-date__control {
      min-height: -webkit-min-content;
      min-height: min-content;
      border: none;
      background-color: #FFFFFF;
      transition: none; }
    .budget-table-wrapper .budget-table-row .due-date__control--isFocused {
      transition: 250ms all;
      box-shadow: 0 0 0 1px #04B5C9; }
  .budget-table-wrapper .header-row {
    color: #626e7a;
    font-weight: 600;
    font-size: 0.9rem; }
    @media (min-width: 1024px) {
      .budget-table-wrapper .header-row {
        margin: 0 20px 0 10px; } }
    @media (min-width: 1920) {
      .budget-table-wrapper .header-row {
        font-size: 1rem; } }
    @media (max-width: 767px) {
      .budget-table-wrapper .header-row .budget-column-remaining {
        padding-right: 6px; } }
    .budget-table-wrapper .header-row .header-amount {
      color: #3E4C59;
      font-weight: 700; }
    @media (min-width: 768px) {
      .budget-table-wrapper .header-row .header-budgeted-cell {
        width: 99px;
        display: flex;
        justify-items: flex-end;
        margin-left: auto; } }
    .budget-table-wrapper .header-row .has-uncategorized {
      border-radius: 4px;
      padding: 1px 3px 1px 6px;
      background-color: #ff9f1a !important; }

.wrapper-multi-month {
  max-width: 100%;
  overflow: auto; }
  @media (min-width: 1024px) {
    .wrapper-multi-month .budget-table-row .budget-column-date, .wrapper-multi-month .budget-table-row .budget-column-budgeted, .wrapper-multi-month .budget-table-row .budget-column-activity, .wrapper-multi-month .budget-table-row .budget-column-remaining {
      flex: 0 0 8.5%; } }

.table-body-wrapper-multi-month {
  max-width: 100%;
  overflow: auto; }

.ltb-row {
  display: flex; }

.ltb-spacer-1 {
  flex: 1 1; }

.ltb-spacer-2 {
  flex: 0 0 8.5%; }

.ltb-multi-month-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 8px;
  flex: 0 0 24.8%; }
  @media (min-width: 1540px) {
    .ltb-multi-month-wrapper {
      flex: 0 0 25.5%; } }
  .ltb-multi-month-wrapper:first-child {
    padding-left: 20px; }
  .ltb-multi-month-wrapper:last-child {
    margin-right: 40px;
    padding-right: 20px; }

.ltb-multi-month-inner {
  border: 1px solid #D2DCE5;
  border-radius: 4px;
  background-color: white;
  margin-left: 20px;
  width: 247px;
  padding-bottom: 14px;
  box-shadow: rgba(223, 225, 227, 0.7) 0 0 4px; }
  .ltb-multi-month-inner .multi-month-date {
    font-size: 1.3rem;
    color: #626e7a;
    font-weight: 700;
    margin-top: 8px;
    margin-bottom: 8px;
    display: block;
    text-align: center; }

.multi-month-line-item {
  padding: 0 16px;
  display: flex;
  flex: 1 1; }
  .multi-month-line-item .multi-month-left-item {
    flex: 1 1;
    color: #616E7C; }
  .multi-month-line-item .multi-month-right-item {
    text-align: right;
    color: #626e7a;
    font-weight: 700; }

.ltb-line-item {
  margin-top: 16px; }
  .ltb-line-item .multi-month-left-item {
    display: flex;
    align-items: center; }
  .ltb-line-item .ltb-amount {
    font-size: 1.3rem; }

.budget-table-body-wrapper {
  width: 100%;
  overflow: auto; }

.budget-category-group {
  margin: 0 4px 8px 8px; }
  @media (min-width: 1024px) {
    .budget-category-group {
      margin: 0 20px 8px 10px; } }

.budget-table-body-wrapper {
  margin: 16px 0;
  overflow: scroll;
  height: 56vh; }
  @media (min-width: 375px) {
    .budget-table-body-wrapper {
      height: 64vh; } }
  @media (min-width: 414px) {
    .budget-table-body-wrapper {
      height: 66vh; } }
  @media (min-width: 768px) {
    .budget-table-body-wrapper {
      height: 66vh; } }
  @media (min-width: 1024px) {
    .budget-table-body-wrapper {
      overflow: overlay;
      flex-grow: 1;
      padding-bottom: 120px; } }
  .budget-table-body-wrapper .category-group-row {
    color: #52606D;
    font-weight: 700;
    font-size: 0.9rem;
    border-bottom: 2px solid #04B5C9; }
    .budget-table-body-wrapper .category-group-row:hover {
      background-color: #eaeaea; }
    @media (min-width: 1920px) {
      .budget-table-body-wrapper .category-group-row {
        font-size: 1rem; } }
    .budget-table-body-wrapper .category-group-row .standard-editable-input {
      color: #52606D;
      font-weight: 700; }
    .budget-table-body-wrapper .category-group-row .button-icon {
      padding: 0 1px; }
      .budget-table-body-wrapper .category-group-row .button-icon:hover {
        background-color: #d1d1d1;
        border-radius: 8px; }
    .budget-table-body-wrapper .category-group-row .collapse-icon {
      position: absolute;
      left: 2px;
      top: 25%; }
      @media (max-width: 767px) {
        .budget-table-body-wrapper .category-group-row .collapse-icon {
          left: -4px; } }
    @media (max-width: 767px) {
      .budget-table-body-wrapper .category-group-row .budget-column-budgeted, .budget-table-body-wrapper .category-group-row .budget-column-remaining {
        padding-right: 3px; } }
  .budget-table-body-wrapper .category-group-selected {
    background-color: #08b4c9;
    color: #FDFEFE; }
    .budget-table-body-wrapper .category-group-selected:hover {
      background-color: #049eb0;
      border-color: #049eb0; }
  .budget-table-body-wrapper .category-row-selected {
    background-color: rgba(8, 180, 201, 0.2); }
    .budget-table-body-wrapper .category-row-selected:hover {
      background-color: rgba(8, 180, 201, 0.3) !important; }
  .budget-table-body-wrapper .budget-table-category-body {
    background-color: #FFFFFF;
    border-left: 1px solid #DFE1E3;
    border-right: 1px solid #DFE1E3;
    border-bottom: 1px solid #DFE1E3;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: rgba(223, 225, 227, 0.7) 0 0 4px; }
  .budget-table-body-wrapper .category-row:first-child {
    border-top: none; }
  .budget-table-body-wrapper .category-row {
    color: #626e7a;
    font-weight: 400;
    font-size: 0.9rem;
    border: none;
    padding-top: 0px;
    padding-bottom: 0px;
    border-top: 1px solid #E8EAEE;
    align-items: center; }
    .budget-table-body-wrapper .category-row:hover {
      background-color: #f2f2f2; }
      .budget-table-body-wrapper .category-row:hover .budget-column-activity span.has-activity:hover {
        cursor: pointer;
        text-decoration: underline; }
    @media (min-width: 1920px) {
      .budget-table-body-wrapper .category-row {
        font-size: 1rem; } }
    .budget-table-body-wrapper .category-row .budget-column-name, .budget-table-body-wrapper .category-row .budget-column-date, .budget-table-body-wrapper .category-row .budget-column-budgeted, .budget-table-body-wrapper .category-row .budget-column-activity, .budget-table-body-wrapper .category-row .budget-column-remaining {
      padding-top: 2px;
      padding-bottom: 2px; }
  .budget-table-body-wrapper .category-row .add-notes-button {
    opacity: 1;
    visibility: visible;
    margin-left: 4px; }
    @media (min-width: 1024px) {
      .budget-table-body-wrapper .category-row .add-notes-button {
        opacity: 0;
        visibility: hidden; } }
  .budget-table-body-wrapper .category-row:hover .add-notes-button {
    opacity: 1;
    visibility: visible; }
    .budget-table-body-wrapper .category-row:hover .add-notes-button:hover {
      cursor: pointer; }
  .budget-table-body-wrapper .due-date__single-value {
    color: #626e7a;
    font-weight: 400;
    align-items: center; }
  .budget-table-body-wrapper .budget-category-input-editable {
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 4px;
    color: #626e7a;
    font-size: inherit;
    font-weight: 400;
    text-align: right;
    max-width: 75px; }
    .budget-table-body-wrapper .budget-category-input-editable:hover {
      transition: 250ms all;
      border: 1px solid #E8EAEE; }
    .budget-table-body-wrapper .budget-category-input-editable:focus {
      box-shadow: 0 0 0 1px #04B5C9; }
    @media (min-width: 1900px) {
      .budget-table-body-wrapper .budget-category-input-editable {
        max-width: 95px; } }

.budget-table-wrapper .month-group {
  flex: 0 0 50%;
  display: flex; }
  .budget-table-wrapper .month-group .budget-column-budgeted, .budget-table-wrapper .month-group .budget-column-activity, .budget-table-wrapper .month-group .budget-column-remaining {
    flex: 1 1; }
    @media (min-width: 1024px) {
      .budget-table-wrapper .month-group .budget-column-budgeted, .budget-table-wrapper .month-group .budget-column-activity, .budget-table-wrapper .month-group .budget-column-remaining {
        flex: 0 0 33.3%; } }

.budget-table-wrapper.wrapper-multi-month .category-group-row {
  padding-top: 0;
  padding-bottom: 0; }

.budget-table-wrapper.wrapper-multi-month .budget-column-name, .budget-table-wrapper.wrapper-multi-month .budget-column-date, .budget-table-wrapper.wrapper-multi-month .budget-column-budgeted, .budget-table-wrapper.wrapper-multi-month .budget-column-activity, .budget-table-wrapper.wrapper-multi-month .budget-column-remaining {
  padding-top: 4px;
  padding-bottom: 4px; }

.budget-table-wrapper.wrapper-multi-month .budget-column-name {
  padding-left: 4px; }

.budget-table-wrapper.wrapper-multi-month .budget-category-group {
  margin-bottom: 0; }

.budget-table-wrapper.wrapper-multi-month .month-group {
  flex: 0 0 25.5%;
  display: flex; }
  .budget-table-wrapper.wrapper-multi-month .month-group .budget-column-budgeted, .budget-table-wrapper.wrapper-multi-month .month-group .budget-column-activity, .budget-table-wrapper.wrapper-multi-month .month-group .budget-column-remaining {
    flex: 0 0 33%; }

.budget-table-wrapper.wrapper-multi-month .budget-column-remaining {
  padding-right: 8px; }

.budget-table-wrapper.wrapper-multi-month .is-middle-column {
  padding: 0 16px 0 0;
  margin-left: 16px; }

.table-body-wrapper-multi-month {
  margin-top: 0; }
  .table-body-wrapper-multi-month .is-middle-column {
    background-color: #f4f4f4; }
    .table-body-wrapper-multi-month .is-middle-column .budget-category-input-editable {
      background-color: #f4f4f4; }
  .table-body-wrapper-multi-month .category-row:hover input.budget-category-input-editable {
    background: #FFFFFF; }
  .table-body-wrapper-multi-month .category-row .budget-column-name, .table-body-wrapper-multi-month .category-group-row .budget-column-name {
    display: flex;
    align-items: center; }
    .table-body-wrapper-multi-month .category-row .budget-column-name input, .table-body-wrapper-multi-month .category-group-row .budget-column-name input {
      white-space: nowrap;
      max-width: 91px;
      text-overflow: ellipsis;
      overflow: hidden; }
      @media (min-width: 1600px) {
        .table-body-wrapper-multi-month .category-row .budget-column-name input, .table-body-wrapper-multi-month .category-group-row .budget-column-name input {
          max-width: 115px; } }
      @media (min-width: 1920px) {
        .table-body-wrapper-multi-month .category-row .budget-column-name input, .table-body-wrapper-multi-month .category-group-row .budget-column-name input {
          max-width: 162px; } }

.credit-card-row-header-label {
  font-size: 0.9rem;
  color: #626e7a;
  font-weight: 600; }

.credit-card-row .budget-column-name {
  padding-left: 3px; }

[data-theme="dark"] .budget-table-wrapper .budget-table-row {
  border-color: #3C4149; }

[data-theme="dark"] .budget-table-wrapper .header-row {
  color: #B1B2B5; }
  [data-theme="dark"] .budget-table-wrapper .header-row .header-amount {
    color: #DBDCDD; }
  [data-theme="dark"] .budget-table-wrapper .header-row .has-uncategorized {
    color: #232323 !important; }

[data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .category-group-row {
  color: #2FBFC4;
  border-color: #2FBFC4; }
  [data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .category-group-row:hover {
    background-color: #3c4751; }
  [data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .category-group-row .standard-editable-input {
    color: #2FBFC4;
    font-weight: 700; }

[data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .category-row {
  color: #C9CBCD;
  border-color: #3C4149; }
  [data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .category-row:hover {
    background-color: #3f4750; }

[data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .due-date__single-value {
  color: #C9CBCD; }

[data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .budget-table-category-body {
  background-color: #343A42;
  border-color: #2E383F;
  box-shadow: #1e1e1e 0 0 4px; }

[data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .due-date__control {
  background-color: #343A42; }

[data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .due-date__control--isFocused {
  box-shadow: 0 0 0 1px #31ACDA; }

[data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .due-date__value-container * {
  color: #C9CBCD !important; }

[data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .due-date__menu {
  background-color: #343A42; }

[data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .due-date__option {
  color: #C9CBCD; }

[data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .due-date__option--isFocused {
  background-color: rgba(4, 181, 201, 0.2);
  color: #E4E7EB; }

[data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .budget-category-input-editable {
  background-color: #343A42;
  color: #C9CBCD; }
  [data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .budget-category-input-editable:hover {
    border: 1px solid #3C4149; }
  [data-theme="dark"] .budget-table-wrapper .budget-table-body-wrapper .budget-category-input-editable:focus {
    box-shadow: 0 0 0 1px #31ACDA; }

[data-theme="dark"] .budget-table-wrapper .ltb-multi-month-inner {
  background-color: #343A42;
  border-color: #2E383F;
  box-shadow: #1e1e1e 0 0 4px; }
  [data-theme="dark"] .budget-table-wrapper .ltb-multi-month-inner .multi-month-date {
    color: #C9CBCD; }
  [data-theme="dark"] .budget-table-wrapper .ltb-multi-month-inner .multi-month-left-item {
    color: #949AA5; }
  [data-theme="dark"] .budget-table-wrapper .ltb-multi-month-inner .multi-month-right-item {
    color: #C9CBCD; }

[data-theme="dark"] .budget-table-wrapper .table-body-wrapper-multi-month .is-middle-column {
  background-color: #22272A; }
  [data-theme="dark"] .budget-table-wrapper .table-body-wrapper-multi-month .is-middle-column .budget-category-input-editable {
    background-color: #22272A; }

[data-theme="dark"] .budget-table-wrapper .table-body-wrapper-multi-month .category-row:hover input.budget-category-input-editable {
  background: #343A42; }
  [data-theme="dark"] .budget-table-wrapper .table-body-wrapper-multi-month .category-row:hover input.budget-category-input-editable:active, [data-theme="dark"] .budget-table-wrapper .table-body-wrapper-multi-month .category-row:hover input.budget-category-input-editable:focus {
    background: #343A42; }

[data-theme="dark"] .budget-table-wrapper .table-body-wrapper-multi-month .category-row input.budget-category-input-editable:active, [data-theme="dark"] .budget-table-wrapper .table-body-wrapper-multi-month .category-row input.budget-category-input-editable:focus {
  background: #343A42; }

[data-theme="dark"] .budget-table-wrapper .credit-card-row-header-label {
  color: #B1B2B5; }

[data-theme="dark"] .budget-table-wrapper .credit-card-row-header-amount {
  color: #DBDCDD; }

.summary-page {
  padding: 16px; }
  .summary-page .header-large {
    margin-bottom: 16px; }
  .summary-page .summary-dropdown-select {
    margin-bottom: 16px; }

.new-budget-form .form-column {
  align-items: center; }

.new-budget-form .default-budget {
  margin-bottom: 0; }

.new-budget-form .input-field {
  width: auto; }

button.button-warning {
  background-color: #DA7A7A;
  border: 1px solid #DA7A7A;
  color: #FDFEFE; }
  button.button-warning:hover {
    background-color: #d87272;
    cursor: pointer; }
  button.button-warning:active {
    background-color: #d66a6a; }

[data-theme="dark"] button.button-warning {
  background-color: #DA7A7A;
  border-color: #DA7A7A;
  color: #FDFEFE; }
  [data-theme="dark"] button.button-warning:hover {
    background-color: #d46666;
    border-color: #d46666;
    cursor: pointer; }
  [data-theme="dark"] button.button-warning:active {
    background-color: #d25e5e;
    border-color: #d25e5e; }

.manage-budgets {
  width: 100%; }

.manage-budget-item {
  display: flex;
  align-items: center;
  padding: 16px 8px;
  border-radius: 4px;
  border: 1px solid #D2DCE5;
  margin-bottom: 8px; }
  .manage-budget-item i {
    margin-right: 8px;
    color: #8ca0af; }
    .manage-budget-item i:hover {
      color: #04B5C9;
      cursor: pointer; }
  .manage-budget-item i.delete-budget:hover {
    color: #DA7A7A; }
  .manage-budget-item i.default-budget {
    color: #4D9EC7; }
  .manage-budget-item .manage-budget-item-name {
    flex: 1 1; }
  .manage-budget-item .manage-budget-item-actions {
    justify-content: flex-end; }

[data-theme="dark"] .manage-budgets .manage-budget-item {
  color: #DBDCDD; }


.manage-payees {
  width: 100%;
  max-height: 400px; }
  .manage-payees .payee-list {
    display: flex;
    height: 300px;
    min-width: 100%;
    margin-bottom: 30px; }
  .manage-payees .form-controls-group {
    width: 100%;
    display: block;
    text-align: right; }
    .manage-payees .form-controls-group button {
      margin-left: 8px; }

[data-theme="dark"] .manage-payees label {
  color: #DBDCDD; }

[data-theme="dark"] .manage-payees input {
  background-color: #262D34;
  border-color: #3C4149;
  color: #C9CBCD; }
  [data-theme="dark"] .manage-payees input:focus {
    border-color: #31ACDA; }

header.budget-details-menu {
  display: flex;
  align-items: center;
  height: 35px;
  padding-left: 16px; }
  header.budget-details-menu .budget-details-wrapper {
    display: flex;
    align-items: center;
    line-height: 1;
    color: #E4E7EB;
    min-width: 150px; }
    header.budget-details-menu .budget-details-wrapper:hover {
      color: #F5F7FA;
      cursor: pointer; }
  header.budget-details-menu .budget-details-logo {
    width: 22px;
    height: 22px;
    margin-right: 4px; }
  header.budget-details-menu .budget-details-name {
    font-size: 1.2rem;
    margin: 0; }
  header.budget-details-menu .budget-details-dropdown {
    position: relative;
    float: right;
    display: inline-block; }
  header.budget-details-menu .budget-details-dropdown-list {
    right: 0;
    margin-top: 4px;
    display: none;
    position: absolute;
    background-color: #FFFFFF;
    padding: 4px 0;
    border-radius: 4px;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1; }
    header.budget-details-menu .budget-details-dropdown-list a {
      display: block;
      color: #52606D;
      padding: 3px 12px;
      font-size: 1rem;
      text-decoration: none; }
      header.budget-details-menu .budget-details-dropdown-list a:hover {
        background-color: #F1F1F1;
        cursor: pointer; }
    header.budget-details-menu .budget-details-dropdown-list span.dropdown-email-wrapper {
      display: block;
      color: #52606D;
      padding: 2px 12px;
      font-size: 1rem; }
      header.budget-details-menu .budget-details-dropdown-list span.dropdown-email-wrapper i {
        display: inline-block; }
      header.budget-details-menu .budget-details-dropdown-list span.dropdown-email-wrapper .dropdown-email {
        display: inline-block;
        font-size: 0.85rem; }
  header.budget-details-menu .display-dropdown {
    display: block; }
  header.budget-details-menu .dropdown-divider {
    background-color: #dbdbdb;
    border: none;
    display: block;
    height: 1px;
    margin: 0.5rem 0; }
  header.budget-details-menu .dropdown-icon {
    margin-right: 8px; }

[data-theme="dark"] header.budget-details-menu .budget-details-dropdown-list {
  background-color: #343A42; }
  [data-theme="dark"] header.budget-details-menu .budget-details-dropdown-list a {
    color: #C9CBCD; }
    [data-theme="dark"] header.budget-details-menu .budget-details-dropdown-list a:hover {
      background-color: #262D34;
      color: #E4E7EB; }

[data-theme="dark"] header.budget-details-menu .dropdown-divider {
  background-color: #949AA5; }

[data-theme="dark"] header.budget-details-menu span.dropdown-email-wrapper {
  color: #C9CBCD; }

.new-account-form .input-field {
  width: auto; }

aside.sidebar {
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #343D49;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  bottom: 0;
  width: 280px;
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }
  @media (max-width: 768px) {
    aside.sidebar {
      overflow: auto; } }
  @media (min-width: 1024px) {
    aside.sidebar {
      width: 275px;
      -webkit-transform: translateX(0%);
              transform: translateX(0%); } }
  aside.sidebar.is-disabled {
    -webkit-transform: translateX(0%) !important;
            transform: translateX(0%) !important; }
    @media (min-width: 1024px) {
      aside.sidebar.is-disabled {
        -webkit-transform: translateX(-100%) !important;
                transform: translateX(-100%) !important; } }
  aside.sidebar .empty-state-msg-accounts {
    display: flex;
    padding-left: 15px;
    margin-top: 8px;
    color: #F5F7FA; }
  aside.sidebar a {
    color: #F5F7FA;
    font-size: 1.3rem;
    text-decoration: none; }
    @media (min-width: 1024px) {
      aside.sidebar a {
        font-size: 1.1rem; } }
  aside.sidebar ul {
    list-style-type: none;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0; }
  aside.sidebar .sidebar-icon {
    margin-right: 8px; }
  aside.sidebar .menu-list {
    line-height: 2; }
    aside.sidebar .menu-list li {
      padding-left: 32px;
      padding-top: 4px;
      padding-bottom: 4px;
      transition: 300ms all; }
      aside.sidebar .menu-list li:hover {
        background-color: #29313a; }
      aside.sidebar .menu-list li a {
        width: 100%;
        display: inline-block;
        padding-left: 16px; }
      aside.sidebar .menu-list li .is-active {
        font-weight: 600; }
  aside.sidebar .uncategorized-icon {
    color: #ff9f1a; }
  aside.sidebar .account-section-title {
    cursor: pointer;
    display: inline-block;
    font-size: 0.85rem;
    color: #F5F7FA;
    padding-left: 16px;
    line-height: 0.5; }
    aside.sidebar .account-section-title:hover {
      cursor: pointer; }
  aside.sidebar .sidebar-accounts-list {
    padding-bottom: 80px; }
  aside.sidebar section.new-account-section {
    display: flex;
    padding: 16px 16px 4px; }
    aside.sidebar section.new-account-section .sidebar-accounts-section-title {
      color: #FDFEFE;
      flex: 1 1;
      font-weight: 600;
      font-size: 1.3rem; }
    aside.sidebar section.new-account-section .new-account-button {
      color: #04B5C9; }
      aside.sidebar section.new-account-section .new-account-button:hover {
        cursor: pointer;
        color: #049eb0; }
  aside.sidebar .accounts-type-divider {
    background-color: #DFE1E3;
    border: none;
    display: block;
    height: 1px;
    margin: 0.5rem 16px; }
  aside.sidebar .account-section-header {
    color: #43D0E2;
    font-weight: 700;
    font-size: 0.85rem;
    text-transform: uppercase;
    padding-left: 16px; }
  aside.sidebar .account-li {
    display: block;
    transition: 300ms all;
    margin-bottom: 2px; }
    aside.sidebar .account-li:hover {
      background-color: #434e5e; }

a.account-links {
  width: 230px;
  display: block;
  padding: 0 16px; }
  @media (min-width: 1024px) {
    a.account-links {
      min-width: 215px;
      width: 93%; } }
  a.account-links .account-wrapper {
    line-height: 1.75;
    display: flex;
    align-items: center; }
    @media (min-width: 1024px) {
      a.account-links .account-wrapper {
        line-height: 1.5;
        padding-right: 4px; } }
    a.account-links .account-wrapper .category-dropdown-toggle {
      right: 10px; }
    a.account-links .account-wrapper .category-dropdown-toggle-mobile {
      position: relative;
      padding: 0 8px;
      margin-right: -25px;
      right: 0; }
    a.account-links .account-wrapper .category-dropdown-menu {
      padding: 8px;
      font-size: 1.1rem; }
      @media (min-width: 768px) {
        a.account-links .account-wrapper .category-dropdown-menu {
          min-width: 152px;
          text-align: right; }
          a.account-links .account-wrapper .category-dropdown-menu span {
            font-size: 12px !important; } }
    a.account-links .account-wrapper .category-dropdown-open li {
      padding: 6px 8px; }
      @media (min-width: 768px) {
        a.account-links .account-wrapper .category-dropdown-open li {
          padding: 0 4px; } }
    a.account-links .account-wrapper .button-icon {
      opacity: 0.6;
      margin-right: 4px; }
      a.account-links .account-wrapper .button-icon:hover {
        opacity: 1; }
  a.account-links .account-name {
    display: inline-block;
    flex: 1 1;
    color: #F5F7FA;
    font-size: 1.3rem;
    font-weight: 600;
    -webkit-margin-after: 6px;
            margin-block-end: 6px;
    -webkit-margin-before: 6px;
            margin-block-start: 6px; }
    @media (min-width: 1024px) {
      a.account-links .account-name {
        font-size: 1rem; } }
  a.account-links .account-balance {
    display: inline-block;
    color: #E4E7EB;
    font-size: 1.3rem; }
    @media (min-width: 1024px) {
      a.account-links .account-balance {
        font-size: 1rem; } }

.expand-collapse-account-section {
  margin-right: 4px;
  color: #E4E7EB; }

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 200ms ease-out; }
  .sidebar-overlay.enabled {
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.2); }
  .sidebar-overlay.disabled {
    z-index: -1;
    background-color: rgba(0, 0, 0, 0); }
  @media (min-width: 1024px) {
    .sidebar-overlay {
      display: none; } }

@media (max-width: 375px) {
  .new-account-form {
    display: block; } }

.new-account-form .input-select__group-heading {
  font-weight: 600;
  text-transform: none;
  font-size: 10px !important;
  color: #4D9EC7; }

.new-account-form .form-split-column .input-select {
  min-width: 141px;
  margin-top: 5px;
  height: 31px; }

.new-account-form .form-split-column .input-select__control {
  height: 35px; }

[data-theme="dark"] aside.sidebar {
  background-color: #22272A; }
  [data-theme="dark"] aside.sidebar a {
    color: #949AA5; }
  [data-theme="dark"] aside.sidebar .is-active {
    color: #C9CBCD; }
  [data-theme="dark"] aside.sidebar .account-name {
    color: #C9CBCD; }
  [data-theme="dark"] aside.sidebar .account-balance {
    color: #949AA5; }
  [data-theme="dark"] aside.sidebar .accounts-type-divider {
    background-color: #949AA5; }
  [data-theme="dark"] aside.sidebar .new-account-form .input-select__input {
    color: #C9CBCD; }

.account-search {
  max-width: 148px;
  margin-left: 16px; }

.account-header-wrapper {
  padding: 16px 12px; }
  .account-header-wrapper .input-field {
    width: 100%; }
  @media (min-width: 1024px) {
    .account-header-wrapper {
      padding: 16px 32px; } }
  .account-header-wrapper .account-header-controls-row {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    padding: 0; }
    @media (min-width: 768px) {
      .account-header-wrapper .account-header-controls-row {
        padding: 8px 0; } }
    .account-header-wrapper .account-header-controls-row .account-header-left-column, .account-header-wrapper .account-header-controls-row .account-header-right-column {
      display: flex;
      flex: 1 1; }
    .account-header-wrapper .account-header-controls-row .top-row {
      align-items: center;
      display: block; }
    .account-header-wrapper .account-header-controls-row .account-header-right-column {
      justify-content: flex-end;
      text-align: right; }
      .account-header-wrapper .account-header-controls-row .account-header-right-column button {
        margin-left: 8px; }
  .account-header-wrapper .upper-row .account-header-left-column {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 37%; }
    @media (min-width: 1024px) {
      .account-header-wrapper .upper-row .account-header-left-column {
        flex: 0 0 40%; } }
    @media (min-width: 1200px) {
      .account-header-wrapper .upper-row .account-header-left-column {
        flex: 1 1; } }
  .account-header-wrapper .upper-row .account-header-right-column {
    display: flex;
    flex: 0 0 63%; }
    @media (min-width: 1024px) {
      .account-header-wrapper .upper-row .account-header-right-column {
        flex: 0 0 60%; } }
    @media (min-width: 1200px) {
      .account-header-wrapper .upper-row .account-header-right-column {
        flex: 1 1; } }
  .account-header-wrapper .account-name {
    display: inline-flex;
    align-items: center;
    color: #52606D;
    font-weight: 700;
    font-size: 1.5rem;
    padding-left: 8px; }
  .account-header-wrapper .account-type {
    display: block;
    min-width: 100%;
    font-weight: 400;
    color: #626e7a;
    padding-left: 8px;
    font-style: italic;
    font-size: 0.9rem; }
  .account-header-wrapper .account-balance-block {
    margin-left: 32px; }
    .account-header-wrapper .account-balance-block .balance-amount {
      display: block;
      font-size: 1.5rem;
      font-weight: 600;
      color: #52606D; }
    .account-header-wrapper .account-balance-block .balance-description {
      font-weight: 400;
      color: #52606D; }
    .account-header-wrapper .account-balance-block .balance-total {
      color: #26a65b;
      font-weight: 700; }
  .account-header-wrapper .bottom-row {
    margin-top: 8px; }
    .account-header-wrapper .bottom-row .account-header-left-column {
      display: flex;
      align-items: center; }
  .account-header-wrapper .account-view-toggle {
    display: inline-block; }
    .account-header-wrapper .account-view-toggle .toggle-item {
      color: #52606D;
      font-size: 1rem;
      font-weight: 600;
      border-radius: 12px;
      padding: 4px 8px;
      margin-right: 4px; }
      .account-header-wrapper .account-view-toggle .toggle-item:hover {
        cursor: pointer; }
    .account-header-wrapper .account-view-toggle .is-toggled {
      background-color: #E4E7EB; }

.ReactVirtualized__Grid__innerScrollContainer {
  min-height: 100%; }

[data-theme="dark"] .account-header-wrapper .account-name {
  color: #E4E7EB; }

[data-theme="dark"] .account-header-wrapper .account-view-toggle .toggle-item {
  color: #949AA5; }

[data-theme="dark"] .account-header-wrapper .account-view-toggle .is-toggled {
  background-color: #343A42; }

[data-theme="dark"] .account-header-wrapper .balance-description {
  color: #C9CBCD; }

[data-theme="dark"] .account-header-wrapper .balance-amount {
  color: #B1B2B5; }

[data-theme="dark"] .account-header-wrapper .balance-total {
  color: #2dda75; }

[data-theme="dark"] .account-header-wrapper .input-field {
  background-color: #262D34;
  border-color: #949AA5;
  color: #C9CBCD; }
  [data-theme="dark"] .account-header-wrapper .input-field:focus {
    border-color: #4D9EC7; }

@media (min-width: 1024px) {
  .ReactVirtualized__List {
    overflow: overlay !important; } }

.transaction-header {
  height: 35px;
  margin-top: -26px; }
  .transaction-header .transaction-header-row {
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;
    border: none;
    display: flex;
    border-bottom: 1px solid #31ACDA;
    padding: 0 16px;
    height: 31px;
    margin: 2px 32px;
    align-items: center;
    border-radius: 2px; }
    .transaction-header .transaction-header-row:hover {
      border: none;
      box-shadow: none;
      border-bottom: 1px solid #31ACDA; }
    .transaction-header .transaction-header-row .transaction-column {
      color: #616E7C;
      font-size: 0.9rem;
      font-weight: 700;
      text-transform: capitalize; }

[data-theme="dark"] .transaction-header .transaction-header-row {
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid #4D9EC7; }
  [data-theme="dark"] .transaction-header .transaction-header-row:hover {
    border: none;
    box-shadow: none;
    border-bottom: 1px solid #4D9EC7; }
  [data-theme="dark"] .transaction-header .transaction-header-row .transaction-column {
    color: #C9CBCD; }

.transaction-row-mobile {
  background-color: #ffffff;
  padding: 8px 30px;
  min-height: 37px;
  margin-bottom: 2px;
  border: 1px solid #ececec;
  border-radius: 2px; }
  .transaction-row-mobile .transaction-mobile-top-row, .transaction-row-mobile .transaction-mobile-bottom-row {
    display: flex;
    padding-right: 8px; }
    .transaction-row-mobile .transaction-mobile-top-row .mobile-label-payee, .transaction-row-mobile .transaction-mobile-top-row .mobile-label-category, .transaction-row-mobile .transaction-mobile-bottom-row .mobile-label-payee, .transaction-row-mobile .transaction-mobile-bottom-row .mobile-label-category {
      flex: 1 1; }
    .transaction-row-mobile .transaction-mobile-top-row .mobile-label-amount, .transaction-row-mobile .transaction-mobile-top-row .mobile-label-date, .transaction-row-mobile .transaction-mobile-bottom-row .mobile-label-amount, .transaction-row-mobile .transaction-mobile-bottom-row .mobile-label-date {
      text-align: right; }
    .transaction-row-mobile .transaction-mobile-top-row .mobile-label-payee, .transaction-row-mobile .transaction-mobile-bottom-row .mobile-label-payee {
      font-weight: 600;
      font-size: 1.2rem;
      color: #3E4C59; }
      .transaction-row-mobile .transaction-mobile-top-row .mobile-label-payee i, .transaction-row-mobile .transaction-mobile-bottom-row .mobile-label-payee i {
        margin-left: 8px;
        color: #C9C9C9; }
      .transaction-row-mobile .transaction-mobile-top-row .mobile-label-payee i.approved, .transaction-row-mobile .transaction-mobile-bottom-row .mobile-label-payee i.approved {
        color: #26a65b; }
    .transaction-row-mobile .transaction-mobile-top-row .mobile-label-amount, .transaction-row-mobile .transaction-mobile-bottom-row .mobile-label-amount {
      font-weight: 600;
      color: #3E4C59;
      font-size: 1.2rem; }
    .transaction-row-mobile .transaction-mobile-top-row .mobile-label-category, .transaction-row-mobile .transaction-mobile-top-row .mobile-label-date, .transaction-row-mobile .transaction-mobile-bottom-row .mobile-label-category, .transaction-row-mobile .transaction-mobile-bottom-row .mobile-label-date {
      font-size: 0.9rem;
      font-weight: 400;
      color: #52606D; }
  .transaction-row-mobile .mobile-transaction-details-icon {
    color: #888888;
    position: absolute;
    right: 10px;
    margin-top: -29px; }
  .transaction-row-mobile .inflow-transaction {
    color: #26a65b !important; }

.mobile-add-transaction-button {
  font-size: 1.2rem !important;
  position: fixed;
  z-index: 19;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 16px 0 !important;
  border-radius: 0 !important; }

[data-theme="dark"] .transaction-row-mobile {
  border-color: #585f6b;
  background-color: #343A42; }
  [data-theme="dark"] .transaction-row-mobile .mobile-label-payee, [data-theme="dark"] .transaction-row-mobile .mobile-label-amount {
    color: #DBDCDD; }
  [data-theme="dark"] .transaction-row-mobile .mobile-label-payee i.approved {
    color: #2dda75; }
  [data-theme="dark"] .transaction-row-mobile .mobile-label-category, [data-theme="dark"] .transaction-row-mobile .mobile-label-date {
    color: #B1B2B5; }
  [data-theme="dark"] .transaction-row-mobile .inflow-transaction {
    color: #2dda75 !important; }

.transfer-type-icon {
  margin-right: 4px; }

.inner-wrapper.account-wrapper {
  height: calc(100vh - 220px); }
  @media (min-width: 1024px) {
    .inner-wrapper.account-wrapper {
      height: calc(100vh - 221px); } }

.transaction-row {
  background-color: #FFFFFF;
  display: block;
  border: 1px solid #ececec;
  padding: 0 16px;
  height: 31px;
  margin: 2px 32px;
  align-items: center;
  border-radius: 2px; }
  .transaction-row:hover {
    transition: all 0.13s;
    border: 1px solid #C9C9C9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.09); }
  .transaction-row .transaction-column {
    display: flex;
    color: #3E4C59;
    margin: 0 2px; }
  .transaction-row .select-column {
    flex: 0 0 2%; }
  .transaction-row .date-column {
    flex: 0 0 12%; }
  .transaction-row .payee-column {
    flex: 0 0 16%; }
  .transaction-row .category-column {
    flex: 0 0 21%; }
  .transaction-row .notes-column {
    flex: 1 1; }
  .transaction-row .amount-column {
    flex: 0 0 8%;
    text-align: right;
    font-weight: 600;
    justify-content: flex-end; }
  .transaction-row .approved-column {
    justify-content: center;
    flex: 0 0 5%; }
    .transaction-row .approved-column i {
      color: #C9C9C9; }
      .transaction-row .approved-column i:hover {
        cursor: pointer;
        transition: 200ms all;
        color: #949AA5; }
    .transaction-row .approved-column i.approved {
      color: #26a65b; }
      .transaction-row .approved-column i.approved:hover {
        color: #26a65b !important; }
  .transaction-row .icon-toggle-split-children {
    margin-left: 4px;
    color: #04B5C9; }
    .transaction-row .icon-toggle-split-children:hover {
      cursor: pointer; }
  .transaction-row .child-collapsed > * {
    display: none !important; }
  .transaction-row .child-collapsed {
    display: none !important;
    padding: 0;
    margin-top: 0; }
  .transaction-row .parent-collapsed {
    margin-bottom: -0.75rem !important; }
  .transaction-row .split-remove-row-btn:hover {
    cursor: pointer; }

.transaction-row-desktop-inner-child {
  display: flex;
  background-color: #f3f3f3; }

.transaction-row-editable .transaction-row-desktop-inner-child {
  margin-left: -16px; }
  .transaction-row-editable .transaction-row-desktop-inner-child .remaining-column {
    text-align: right;
    justify-content: flex-end; }
    .transaction-row-editable .transaction-row-desktop-inner-child .remaining-column span {
      color: #52606D;
      font-weight: 600;
      display: block; }

.transaction-row-readonly {
  display: flex; }
  .transaction-row-readonly .transaction-column {
    padding-left: 4px; }

.uncategorized-row {
  border: 1px solid #ff9f1a !important; }

.transaction-row-editable, .row-selected {
  border: 1px solid #04B5C9;
  background-color: rgba(4, 181, 201, 0.1); }
  .transaction-row-editable:hover, .row-selected:hover {
    border: 1px solid #04B5C9;
    cursor: pointer; }
  .transaction-row-editable .amount-column .editable-cell-input-standalone, .row-selected .amount-column .editable-cell-input-standalone {
    text-align: right; }

.transaction-row-desktop-inner {
  width: 100%;
  height: 33px;
  display: flex;
  align-items: center; }
  .transaction-row-desktop-inner .editable-cell-input-standalone {
    background: #FFFFFF;
    width: 80%;
    border-radius: 4px;
    border-width: 0px;
    height: 1.3em;
    padding: 4px 4px 4px 16px;
    font-size: inherit; }
    .transaction-row-desktop-inner .editable-cell-input-standalone:focus {
      border: 2px solid #04B5C9; }
  .transaction-row-desktop-inner .DayPickerInput input {
    height: 1.3em;
    font-size: inherit;
    padding: 4px;
    outline: none;
    box-shadow: none;
    border-radius: 4px;
    border-width: 0px; }
    .transaction-row-desktop-inner .DayPickerInput input:focus {
      border: 2px solid #04B5C9; }

.transaction-row-desktop-inner-child {
  padding: 4px 15px;
  background: #f3f3f3; }
  .transaction-row-desktop-inner-child:hover {
    cursor: pointer; }

.transaction-row-expanded-toolbar {
  display: block;
  text-align: right;
  background-color: #f7f7f7;
  width: 100%;
  margin: 0;
  padding: 8px 0;
  justify-content: flex-end;
  border: none; }
  .transaction-row-expanded-toolbar:hover {
    border: none;
    box-shadow: none;
    cursor: initial; }
  .transaction-row-expanded-toolbar button {
    margin-left: 8px; }
  .transaction-row-expanded-toolbar .button-primary {
    margin-right: 40px; }

.transaction-checkbox-container {
  position: relative;
  cursor: pointer;
  background-color: #333333;
  margin-right: 15px; }
  .transaction-checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
  .transaction-checkbox-container .transaction-checkmark {
    position: absolute;
    top: -7px;
    left: -5px;
    height: 14px;
    width: 14px;
    background-color: #D8D8D8; }

.transaction-checkbox-container:hover input ~ .transaction-checkmark {
  background-color: #CCCCCC; }

.transaction-checkbox-container input:checked ~ .transaction-checkmark {
  background-color: #4D9EC7; }

.transaction-checkmark::after {
  content: "";
  position: absolute;
  display: none; }

.transaction-checkbox-container input:checked ~ .transaction-checkmark:after {
  display: block; }

.transaction-checkbox-container .transaction-checkmark:after {
  left: 4px;
  top: 1px;
  width: 3px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.transaction-nil-amount, .transaction-nil-amount-inflow, .transaction-nil-amount-outflow {
  font-weight: 400 !important;
  color: #626e7a !important; }

.transaction-neg-amount, .transaction-neg-amount-inflow, .transaction-neg-amount-outflow {
  color: #C26E6C !important;
  font-weight: bold !important; }

.transaction-pos-amount, .transaction-pos-amount-inflow, .transaction-pos-amount-outflow {
  font-weight: bold !important; }

.editable-cell-input {
  background: #FFFFFF;
  width: 80%;
  border-radius: 4px; }
  .editable-cell-input .editable-cell-input__control {
    outline: none;
    height: 1.3em;
    border: 1px solid transparent;
    align-items: center;
    border-radius: 4px;
    justify-content: flex-start;
    line-height: 1.5;
    padding: 3px;
    position: static;
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-shadow: none; }
  .editable-cell-input .editable-cell-input__value-container {
    position: static;
    margin-top: -6px; }
  .editable-cell-input .editable-cell-input__single-value {
    width: 90%; }

.include-now-icon {
  margin-left: 12px;
  margin-right: -32px; }

i.include-now-icon-filled {
  margin-left: 12px;
  margin-right: -32px;
  color: #04B5C9 !important; }

i.moved-right {
  margin-right: -42px; }

[data-theme="dark"] .transaction-row-readonly {
  border-color: #585f6b;
  background-color: #343A42; }
  [data-theme="dark"] .transaction-row-readonly:hover {
    border-color: #767f8e;
    box-shadow: 0 2px 4px #1e1e1e; }
  [data-theme="dark"] .transaction-row-readonly .transaction-column {
    color: #B1B2B5; }

[data-theme="dark"] input[type="checkbox" i] {
  background-color: #262D34 !important; }
  [data-theme="dark"] input[type="checkbox" i] .editable-cell-input-standalone, [data-theme="dark"] input[type="checkbox" i] .editable-cell-input, [data-theme="dark"] input[type="checkbox" i] .DayPickerInput input, [data-theme="dark"] input[type="checkbox" i] .editable-cell-input__placeholder, [data-theme="dark"] input[type="checkbox" i] .editable-cell-input__single-value {
    background: #262D34;
    color: #DBDCDD; }

[data-theme="dark"] .transaction-row-editable, [data-theme="dark"] .row-selected {
  border: 1px solid #4D9EC7;
  background-color: rgba(4, 181, 201, 0.2); }
  [data-theme="dark"] .transaction-row-editable:hover, [data-theme="dark"] .row-selected:hover {
    border: 1px solid #4D9EC7;
    cursor: pointer; }
  [data-theme="dark"] .transaction-row-editable .transaction-column, [data-theme="dark"] .row-selected .transaction-column {
    color: #B1B2B5; }
  [data-theme="dark"] .transaction-row-editable .editable-cell-input-standalone, [data-theme="dark"] .transaction-row-editable .editable-cell-input, [data-theme="dark"] .transaction-row-editable .DayPickerInput input, [data-theme="dark"] .transaction-row-editable .editable-cell-input__placeholder, [data-theme="dark"] .transaction-row-editable .editable-cell-input__single-value, [data-theme="dark"] .row-selected .editable-cell-input-standalone, [data-theme="dark"] .row-selected .editable-cell-input, [data-theme="dark"] .row-selected .DayPickerInput input, [data-theme="dark"] .row-selected .editable-cell-input__placeholder, [data-theme="dark"] .row-selected .editable-cell-input__single-value {
    background: #262D34;
    color: #DBDCDD; }
  [data-theme="dark"] .transaction-row-editable .editable-cell-input__value-container *,
  [data-theme="dark"] .transaction-row-editable .editable-cell-input__value-container--hasValue *, [data-theme="dark"] .row-selected .editable-cell-input__value-container *,
  [data-theme="dark"] .row-selected .editable-cell-input__value-container--hasValue * {
    color: #DBDCDD !important; }
  [data-theme="dark"] .transaction-row-editable .editable-cell-input__menu, [data-theme="dark"] .row-selected .editable-cell-input__menu {
    background-color: #343A42; }
  [data-theme="dark"] .transaction-row-editable .editable-cell-input__option, [data-theme="dark"] .row-selected .editable-cell-input__option {
    color: #C9CBCD; }
  [data-theme="dark"] .transaction-row-editable .editable-cell-input__option--isFocused, [data-theme="dark"] .row-selected .editable-cell-input__option--isFocused {
    background-color: rgba(4, 181, 201, 0.2);
    color: #E4E7EB; }
  [data-theme="dark"] .transaction-row-editable .editable-cell-input__group-heading, [data-theme="dark"] .row-selected .editable-cell-input__group-heading {
    color: #04B5C9; }
  [data-theme="dark"] .transaction-row-editable .DayPickerInput-Overlay, [data-theme="dark"] .row-selected .DayPickerInput-Overlay {
    background-color: #343A42;
    color: #C9CBCD;
    box-shadow: rgba(30, 30, 30, 0.9) 0 4px 10px; }

[data-theme="dark"] .transaction-row-desktop-inner-child {
  background-color: #2E383F; }
  [data-theme="dark"] .transaction-row-desktop-inner-child .remaining-column span {
    color: #C9CBCD; }

[data-theme="dark"] .transaction-row-expanded-toolbar {
  background-color: #262D34; }

[data-theme="dark"] .approved-column i.approved {
  color: #2dda75; }

header.header {
  background-color: #FFFFFF;
  border-bottom: 1px solid #D2DCE5;
  display: flex;
  height: 44px;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px; }
  @media (min-width: 1024px) {
    header.header {
      height: 35px; } }
  header.header .sidebar-collapse-icon:hover {
    cursor: pointer; }
  header.header .header-right-side {
    align-items: stretch;
    display: flex;
    justify-content: flex-end;
    margin-left: auto; }
    header.header .header-right-side ul.header-nav {
      list-style-type: none;
      -webkit-padding-start: 0;
              padding-inline-start: 0;
      -webkit-margin-before: 0;
              margin-block-start: 0;
      -webkit-margin-after: 0;
              margin-block-end: 0; }
      header.header .header-right-side ul.header-nav li.header-nav-item {
        display: inline-block;
        padding: 8px 8px;
        margin-left: 16px; }
        header.header .header-right-side ul.header-nav li.header-nav-item:hover {
          border-radius: 4px;
          cursor: pointer;
          background-color: #F1f2f2; }
        header.header .header-right-side ul.header-nav li.header-nav-item span {
          color: #52606D;
          text-align: center;
          font-weight: 600; }

[data-theme="dark"] header.header {
  background-color: #343A42;
  border-bottom: 1px solid #343A42; }
  [data-theme="dark"] header.header .sidebar-collapse-icon {
    color: #C9CBCD; }

section.app-viewport {
  background-color: #F7F7F7;
  transition: margin-left 300ms;
  min-height: 100vh; }
  @media (min-width: 1024px) {
    section.app-viewport {
      margin-left: 275px; } }
  section.app-viewport.sidebar-disabled {
    margin-left: 0px; }

main.inner-wrapper {
  padding: 8px; }
  @media (min-width: 1024px) {
    main.inner-wrapper {
      padding: 16px; } }

[data-theme="dark"] section.app-viewport {
  background-color: #262D34;
  color: #C9CBCD; }

.registration-logo {
  position: absolute; }
  .registration-logo img {
    padding: 20px;
    width: 220px;
    height: auto; }

.registration-wrapper {
  background-color: #262D34;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw; }
  .registration-wrapper .card-column {
    width: 290px; }
    .registration-wrapper .card-column .registration-title {
      margin-bottom: 16px;
      text-align: center;
      font-size: 2rem;
      color: #FDFEFE; }
    .registration-wrapper .card-column .registration-form {
      box-shadow: #1e1e1e 0 0 4px;
      background-color: #f7f7f7;
      padding: 15px 25px;
      min-height: 260px;
      margin-bottom: 20px;
      border-radius: 4px; }

.registration-form-inner {
  margin-top: 16px; }
  .registration-form-inner form div {
    display: flex;
    flex-flow: column; }
  .registration-form-inner .input-label {
    font-weight: 600;
    font-size: 1rem;
    color: #52606D;
    flex: 1 1;
    display: flex;
    margin-bottom: 4px; }
    .registration-form-inner .input-label i {
      margin-left: 2px; }
  .registration-form-inner .registration-form-button {
    margin-top: 8px;
    padding: 6px 16px;
    width: 100%; }
  .registration-form-inner .forgot-password-link {
    display: block;
    width: 100%;
    margin-top: 8px; }
    .registration-form-inner .forgot-password-link button {
      width: 100%;
      padding: 6px 16px; }
  .registration-form-inner .form-input {
    -webkit-appearance: none;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    height: 2.25em;
    justify-content: flex-start;
    line-height: 1.5;
    padding: 4px 8px;
    position: relative;
    margin-bottom: 4px;
    vertical-align: top;
    background-color: #fff;
    border-color: #dbdbdb;
    color: #363636;
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
    max-width: 100%; }
    .registration-form-inner .form-input:focus {
      border-color: #31ACDA; }

.no-account {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-flow: column; }
  .no-account .no-account-text {
    width: 100%;
    color: #C9CBCD; }
  .no-account a {
    margin: 0 auto;
    width: 110px; }
  .no-account button {
    font-weight: 700;
    color: #04B5C9; }

.demo-text {
  line-height: 1.5em;
  text-align: center;
  margin-top: 20px;
  display: block;
  font-size: 0.85rem;
  color: #626e7a; }

@media (min-width: 1024px) {
  .mobile-only {
    display: none; } }

@media (max-width: 1023px) {
  .mobile-hidden {
    display: none !important; } }

@media (max-width: 1199px) {
  .mobile-and-tablet-hidden {
    display: none !important; } }

@media (max-width: 640px) {
  .tablet-and-desktop-only {
    display: none !important; } }

@media (min-width: 641px) {
  .tablet-and-desktop-hidden {
    display: none !important; } }

@media (max-width: 1023px) {
  .desktop-only {
    display: none; } }

html * {
  font-family: 'Open Sans', sans-serif; }

html, body {
  font-family: 'Open Sans', sans-serif;
  background-color: #F7F7F7;
  font-size: 12px;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0; }
  html h1, html h2, html h3, html h4, body h1, body h2, body h3, body h4 {
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0; }
  html button:focus, body button:focus {
    outline: none; }

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus,
body:not(.user-is-tabbing) *:focus {
  outline: none;
  outline: -webkit-focus-ring-color auto 0px; }

.form-body {
  display: flex;
  width: 100%;
  flex-flow: column; }
  .form-body .input-select__indicator.input-select__dropdown-indicator {
    padding: 0 4px 0 0;
    color: #C9C9C9; }
  .form-body .input-select {
    min-width: 100%; }
    @media (min-width: 1024px) {
      .form-body .input-select {
        min-width: 110px; } }
  .form-body .input-select__menu {
    min-width: 90%; }
    @media (min-width: 1024px) {
      .form-body .input-select__menu {
        min-width: -webkit-max-content;
        min-width: max-content; } }
    .form-body .input-select__menu * {
      font-size: 1rem; }
  @media (max-width: 1023px) {
    .form-body .input-select__option {
      padding: 8px 8px; } }
  .form-body .long-select.input-select {
    min-width: 160px; }
  .form-body .input-select__control {
    min-height: initial;
    border-color: #D2DCE5;
    background-color: #ffffff; }
    .form-body .input-select__control:hover {
      cursor: pointer;
      border-color: #D2DCE5; }
  .form-body .input-select__control--isFocused {
    transition: 200ms all;
    border: 1px solid #04B5C9;
    box-shadow: none; }
    .form-body .input-select__control--isFocused:hover {
      border-color: #04B5C9; }
  .form-body .form-controls-group {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px; }
    .form-body .form-controls-group button {
      margin-left: 8px; }
  .form-body .form-section {
    font-size: 1rem;
    color: #4D9EC7;
    font-weight: 700;
    display: inline-flex;
    margin-bottom: 6px; }
    @media (min-width: 1024px) {
      .form-body .form-section:not(:first-child) {
        margin-top: 16px; } }
  .form-body .form-group {
    display: flex;
    width: 100%;
    flex-flow: column;
    margin-bottom: 8px; }
  .form-body .form-column {
    flex-flow: column; }
    @media (min-width: 1024px) {
      .form-body .form-column {
        flex-flow: row; } }
  .form-body .form-split-column {
    margin-bottom: 16px; }
    @media (max-width: 1024px) {
      .form-body .form-split-column {
        display: flex;
        flex-flow: column; } }
    @media (min-width: 1024px) {
      .form-body .form-split-column {
        margin-bottom: 0; } }
    @media (min-width: 1024px) {
      .form-body .form-split-column:not(:last-child) {
        padding-right: 16px; } }

.input-label {
  font-weight: 600;
  font-size: 0.85rem;
  color: #52606D;
  flex: 1 1;
  display: flex;
  margin-bottom: 4px; }
  .input-label i {
    margin-left: 2px; }

.always-on-btn {
  opacity: 1 !important;
  visibility: visible !important; }

.input-field {
  padding: 8px 8px;
  background-color: #ffffff;
  color: #3E4C59;
  border-radius: 4px;
  border: 1px solid #D2DCE5;
  flex: 1 1; }
  @media (min-width: 1024px) {
    .input-field:focus, .input-field:active {
      transition: 200ms all;
      border-color: #04B5C9; } }

@media (max-width: 767px) {
  .DayPickerInput-Overlay {
    position: fixed; } }

@media (max-width: 767px) {
  .DayPickerInput {
    flex: 1 1;
    display: flex; } }

.transaction-row-desktop-inner .DayPickerInput .repeat-frequency {
  margin-top: 6px;
  margin-bottom: 4px; }

.transaction-row-desktop-inner .DayPickerInput .repeat-frequency-detail .is-horizontal {
  margin-top: 8px; }

.transaction-row-desktop-inner .DayPickerInput .left-field, .transaction-row-desktop-inner .DayPickerInput .right-field {
  display: inline-block; }

.transaction-row-desktop-inner .DayPickerInput input.input-field {
  padding: 8px 8px;
  background-color: #ffffff;
  color: #3E4C59;
  border-radius: 4px;
  max-width: 55px;
  margin: 0 8px;
  border: 1px solid #D2DCE5; }
  .transaction-row-desktop-inner .DayPickerInput input.input-field:focus, .transaction-row-desktop-inner .DayPickerInput input.input-field:active {
    transition: 200ms all;
    border-color: #04B5C9; }

.neg-val {
  color: #C26E6C !important;
  font-weight: bold !important; }

.centered-loading {
  color: #04B5C9;
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  font-size: 4rem;
  vertical-align: middle;
  transition: 300ms all; }

[aria-label]:after {
  color: #F5F7FA !important;
  --balloon-color: #3E4C59; }

[data-theme="dark"] html, body {
  background-color: #262D34; }

@media (min-width: 1024px) {
  main.inner-wrapper, .inner-wrapper {
    height: calc(100vh - 45px); } }

@media (min-width: 1200px) {
  main.inner-wrapper, .inner-wrapper {
    height: 100%;
    overflow-y: auto; } }

main.inner-wrapper .page-title, .inner-wrapper .page-title {
  color: #626e7a;
  display: inline-block;
  font-size: 1.3rem;
  margin-right: 8px;
  line-height: 1; }
  main.inner-wrapper .page-title:hover, .inner-wrapper .page-title:hover {
    cursor: default; }

[data-theme="dark"] main.inner-wrapper .page-title {
  color: #C9CBCD; }

[data-theme="dark"] .form-body .input-label {
  color: #DBDCDD; }

[data-theme="dark"] .form-body .input-field, [data-theme="dark"] .form-body .input-select__control {
  background-color: #262D34;
  border-color: #3C4149;
  color: #C9CBCD; }
  [data-theme="dark"] .form-body .input-field:focus, [data-theme="dark"] .form-body .input-select__control:focus {
    border-color: #31ACDA; }

[data-theme="dark"] .form-body .input-select__menu {
  background-color: #343A42; }

[data-theme="dark"] .form-body .input-select__option {
  color: #DBDCDD; }

[data-theme="dark"] .form-body .input-select__option--isFocused {
  background-color: #4D9EC7; }

[data-theme="dark"] .form-body .input-select__single-value {
  color: #C9CBCD; }

[data-theme="dark"] .form-body .form-section {
  color: #04B5C9; }

[data-theme="dark"] .form-body .editable-cell-input__menu {
  background-color: #343A42; }

[data-theme="dark"] .form-body .editable-cell-input__option {
  color: #C9CBCD; }

[data-theme="dark"] .form-body .editable-cell-input__option--isFocused {
  background-color: rgba(4, 181, 201, 0.2);
  color: #E4E7EB; }

[data-theme="dark"] .form-body .editable-cell-input__group-heading {
  color: #04B5C9; }

[data-theme="dark"] .form-body .DayPickerInput-Overlay {
  background-color: #343A42;
  color: #C9CBCD;
  box-shadow: rgba(30, 30, 30, 0.9) 0 4px 10px; }

