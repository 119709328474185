@media (min-width: 1024px) {
  .ReactVirtualized__List {
    overflow: overlay !important; } }

.transaction-header {
  height: 35px;
  margin-top: -26px; }
  .transaction-header .transaction-header-row {
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;
    border: none;
    display: flex;
    border-bottom: 1px solid #31ACDA;
    padding: 0 16px;
    height: 31px;
    margin: 2px 32px;
    align-items: center;
    border-radius: 2px; }
    .transaction-header .transaction-header-row:hover {
      border: none;
      box-shadow: none;
      border-bottom: 1px solid #31ACDA; }
    .transaction-header .transaction-header-row .transaction-column {
      color: #616E7C;
      font-size: 0.9rem;
      font-weight: 700;
      text-transform: capitalize; }

[data-theme="dark"] .transaction-header .transaction-header-row {
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid #4D9EC7; }
  [data-theme="dark"] .transaction-header .transaction-header-row:hover {
    border: none;
    box-shadow: none;
    border-bottom: 1px solid #4D9EC7; }
  [data-theme="dark"] .transaction-header .transaction-header-row .transaction-column {
    color: #C9CBCD; }
