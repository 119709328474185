.manage-budgets {
  width: 100%; }

.manage-budget-item {
  display: flex;
  align-items: center;
  padding: 16px 8px;
  border-radius: 4px;
  border: 1px solid #D2DCE5;
  margin-bottom: 8px; }
  .manage-budget-item i {
    margin-right: 8px;
    color: #8ca0af; }
    .manage-budget-item i:hover {
      color: #04B5C9;
      cursor: pointer; }
  .manage-budget-item i.delete-budget:hover {
    color: #DA7A7A; }
  .manage-budget-item i.default-budget {
    color: #4D9EC7; }
  .manage-budget-item .manage-budget-item-name {
    flex: 1; }
  .manage-budget-item .manage-budget-item-actions {
    justify-content: flex-end; }

[data-theme="dark"] .manage-budgets .manage-budget-item {
  color: #DBDCDD; }
