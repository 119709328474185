.ltb-mobile-month {
  position: absolute;
  right: 16px;
  top: 45px; }
  .ltb-mobile-month .left-to-budget-line-item span {
    display: block;
    font-size: 0.9rem;
    text-align: right;
    color: #616E7C;
    font-weight: 600; }
  .ltb-mobile-month .left-to-budget-line-item .ltb-amount {
    font-size: 1.3rem;
    font-weight: 700;
    color: #52606D; }
  .ltb-mobile-month .ltb-loading {
    color: #04B5C9;
    width: 100%;
    display: inline-block;
    margin-top: 12px;
    margin-right: 16px;
    font-size: 2rem;
    transition: 300ms all; }

[data-theme="dark"] .ltb-mobile-month .left-to-budget-line-item span {
  font-weight: 600;
  color: #B1B2B5; }

[data-theme="dark"] .ltb-mobile-month .left-to-budget-line-item .ltb-amount {
  color: #C9CBCD;
  font-weight: 700; }
