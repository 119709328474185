button.button-base {
  font-size: 1rem;
  background-color: #FFFFFF;
  border: 1px solid #DFE1E3;
  color: #52606D;
  border-radius: 4px;
  padding: 5px 8px;
  font-weight: 600;
  letter-spacing: 1;
  box-shadow: rgba(223, 225, 227, 0.7) 0 0 4px; }
  button.button-base:hover {
    cursor: pointer; }
  button.button-base:focus {
    box-shadow: none; }
  button.button-base .button-icon {
    margin-right: 4px; }

button.is-disabled {
  opacity: 0.6;
  color: #B1B2B5;
  box-shadow: none !important;
  border-color: rgba(0, 0, 0, 0) !important; }
  button.is-disabled:hover {
    cursor: initial !important; }

[data-theme="dark"] button.button-base {
  background-color: #343A42;
  border-color: #343A42;
  color: #C9CBCD;
  box-shadow: #1e1e1e 0 0 4px; }
