header.budget-details-menu {
  display: flex;
  align-items: center;
  height: 35px;
  padding-left: 16px; }
  header.budget-details-menu .budget-details-wrapper {
    display: flex;
    align-items: center;
    line-height: 1;
    color: #E4E7EB;
    min-width: 150px; }
    header.budget-details-menu .budget-details-wrapper:hover {
      color: #F5F7FA;
      cursor: pointer; }
  header.budget-details-menu .budget-details-logo {
    width: 22px;
    height: 22px;
    margin-right: 4px; }
  header.budget-details-menu .budget-details-name {
    font-size: 1.2rem;
    margin: 0; }
  header.budget-details-menu .budget-details-dropdown {
    position: relative;
    float: right;
    display: inline-block; }
  header.budget-details-menu .budget-details-dropdown-list {
    right: 0;
    margin-top: 4px;
    display: none;
    position: absolute;
    background-color: #FFFFFF;
    padding: 4px 0;
    border-radius: 4px;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1; }
    header.budget-details-menu .budget-details-dropdown-list a {
      display: block;
      color: #52606D;
      padding: 3px 12px;
      font-size: 1rem;
      text-decoration: none; }
      header.budget-details-menu .budget-details-dropdown-list a:hover {
        background-color: #F1F1F1;
        cursor: pointer; }
    header.budget-details-menu .budget-details-dropdown-list span.dropdown-email-wrapper {
      display: block;
      color: #52606D;
      padding: 2px 12px;
      font-size: 1rem; }
      header.budget-details-menu .budget-details-dropdown-list span.dropdown-email-wrapper i {
        display: inline-block; }
      header.budget-details-menu .budget-details-dropdown-list span.dropdown-email-wrapper .dropdown-email {
        display: inline-block;
        font-size: 0.85rem; }
  header.budget-details-menu .display-dropdown {
    display: block; }
  header.budget-details-menu .dropdown-divider {
    background-color: #dbdbdb;
    border: none;
    display: block;
    height: 1px;
    margin: 0.5rem 0; }
  header.budget-details-menu .dropdown-icon {
    margin-right: 8px; }

[data-theme="dark"] header.budget-details-menu .budget-details-dropdown-list {
  background-color: #343A42; }
  [data-theme="dark"] header.budget-details-menu .budget-details-dropdown-list a {
    color: #C9CBCD; }
    [data-theme="dark"] header.budget-details-menu .budget-details-dropdown-list a:hover {
      background-color: #262D34;
      color: #E4E7EB; }

[data-theme="dark"] header.budget-details-menu .dropdown-divider {
  background-color: #949AA5; }

[data-theme="dark"] header.budget-details-menu span.dropdown-email-wrapper {
  color: #C9CBCD; }
