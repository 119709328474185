aside.sidebar {
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #343D49;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  bottom: 0;
  width: 280px;
  transition: transform 300ms;
  transform: translateX(-100%); }
  @media (max-width: 768px) {
    aside.sidebar {
      overflow: auto; } }
  @media (min-width: 1024px) {
    aside.sidebar {
      width: 275px;
      transform: translateX(0%); } }
  aside.sidebar.is-disabled {
    transform: translateX(0%) !important; }
    @media (min-width: 1024px) {
      aside.sidebar.is-disabled {
        transform: translateX(-100%) !important; } }
  aside.sidebar .empty-state-msg-accounts {
    display: flex;
    padding-left: 15px;
    margin-top: 8px;
    color: #F5F7FA; }
  aside.sidebar a {
    color: #F5F7FA;
    font-size: 1.3rem;
    text-decoration: none; }
    @media (min-width: 1024px) {
      aside.sidebar a {
        font-size: 1.1rem; } }
  aside.sidebar ul {
    list-style-type: none;
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0; }
  aside.sidebar .sidebar-icon {
    margin-right: 8px; }
  aside.sidebar .menu-list {
    line-height: 2; }
    aside.sidebar .menu-list li {
      padding-left: 32px;
      padding-top: 4px;
      padding-bottom: 4px;
      transition: 300ms all; }
      aside.sidebar .menu-list li:hover {
        background-color: #29313a; }
      aside.sidebar .menu-list li a {
        width: 100%;
        display: inline-block;
        padding-left: 16px; }
      aside.sidebar .menu-list li .is-active {
        font-weight: 600; }
  aside.sidebar .uncategorized-icon {
    color: #ff9f1a; }
  aside.sidebar .account-section-title {
    cursor: pointer;
    display: inline-block;
    font-size: 0.85rem;
    color: #F5F7FA;
    padding-left: 16px;
    line-height: 0.5; }
    aside.sidebar .account-section-title:hover {
      cursor: pointer; }
  aside.sidebar .sidebar-accounts-list {
    padding-bottom: 80px; }
  aside.sidebar section.new-account-section {
    display: flex;
    padding: 16px 16px 4px; }
    aside.sidebar section.new-account-section .sidebar-accounts-section-title {
      color: #FDFEFE;
      flex: 1;
      font-weight: 600;
      font-size: 1.3rem; }
    aside.sidebar section.new-account-section .new-account-button {
      color: #04B5C9; }
      aside.sidebar section.new-account-section .new-account-button:hover {
        cursor: pointer;
        color: #049eb0; }
  aside.sidebar .accounts-type-divider {
    background-color: #DFE1E3;
    border: none;
    display: block;
    height: 1px;
    margin: 0.5rem 16px; }
  aside.sidebar .account-section-header {
    color: #43D0E2;
    font-weight: 700;
    font-size: 0.85rem;
    text-transform: uppercase;
    padding-left: 16px; }
  aside.sidebar .account-li {
    display: block;
    transition: 300ms all;
    margin-bottom: 2px; }
    aside.sidebar .account-li:hover {
      background-color: #434e5e; }

a.account-links {
  width: 230px;
  display: block;
  padding: 0 16px; }
  @media (min-width: 1024px) {
    a.account-links {
      min-width: 215px;
      width: 93%; } }
  a.account-links .account-wrapper {
    line-height: 1.75;
    display: flex;
    align-items: center; }
    @media (min-width: 1024px) {
      a.account-links .account-wrapper {
        line-height: 1.5;
        padding-right: 4px; } }
    a.account-links .account-wrapper .category-dropdown-toggle {
      right: 10px; }
    a.account-links .account-wrapper .category-dropdown-toggle-mobile {
      position: relative;
      padding: 0 8px;
      margin-right: -25px;
      right: 0; }
    a.account-links .account-wrapper .category-dropdown-menu {
      padding: 8px;
      font-size: 1.1rem; }
      @media (min-width: 768px) {
        a.account-links .account-wrapper .category-dropdown-menu {
          min-width: 152px;
          text-align: right; }
          a.account-links .account-wrapper .category-dropdown-menu span {
            font-size: 12px !important; } }
    a.account-links .account-wrapper .category-dropdown-open li {
      padding: 6px 8px; }
      @media (min-width: 768px) {
        a.account-links .account-wrapper .category-dropdown-open li {
          padding: 0 4px; } }
    a.account-links .account-wrapper .button-icon {
      opacity: 0.6;
      margin-right: 4px; }
      a.account-links .account-wrapper .button-icon:hover {
        opacity: 1; }
  a.account-links .account-name {
    display: inline-block;
    flex: 1;
    color: #F5F7FA;
    font-size: 1.3rem;
    font-weight: 600;
    margin-block-end: 6px;
    margin-block-start: 6px; }
    @media (min-width: 1024px) {
      a.account-links .account-name {
        font-size: 1rem; } }
  a.account-links .account-balance {
    display: inline-block;
    color: #E4E7EB;
    font-size: 1.3rem; }
    @media (min-width: 1024px) {
      a.account-links .account-balance {
        font-size: 1rem; } }

.expand-collapse-account-section {
  margin-right: 4px;
  color: #E4E7EB; }

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 200ms ease-out; }
  .sidebar-overlay.enabled {
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.2); }
  .sidebar-overlay.disabled {
    z-index: -1;
    background-color: rgba(0, 0, 0, 0); }
  @media (min-width: 1024px) {
    .sidebar-overlay {
      display: none; } }

@media (max-width: 375px) {
  .new-account-form {
    display: block; } }

.new-account-form .input-select__group-heading {
  font-weight: 600;
  text-transform: none;
  font-size: 10px !important;
  color: #4D9EC7; }

.new-account-form .form-split-column .input-select {
  min-width: 141px;
  margin-top: 5px;
  height: 31px; }

.new-account-form .form-split-column .input-select__control {
  height: 35px; }

[data-theme="dark"] aside.sidebar {
  background-color: #22272A; }
  [data-theme="dark"] aside.sidebar a {
    color: #949AA5; }
  [data-theme="dark"] aside.sidebar .is-active {
    color: #C9CBCD; }
  [data-theme="dark"] aside.sidebar .account-name {
    color: #C9CBCD; }
  [data-theme="dark"] aside.sidebar .account-balance {
    color: #949AA5; }
  [data-theme="dark"] aside.sidebar .accounts-type-divider {
    background-color: #949AA5; }
  [data-theme="dark"] aside.sidebar .new-account-form .input-select__input {
    color: #C9CBCD; }
